import React from "react";
// Customizable Area Start
import {
    Avatar,
    Box,
    IconButton,
    styled,
} from "@material-ui/core";
import { CreatorBanner, like, Outlined, Share, AddGrayIc, group } from "./assets";
import CreatorMallController, { Props, StoryData } from "./CreatorMallController";
// Customizable Area End

export default class CreatorMall extends CreatorMallController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderCard = () => {
        return (
            <CardOuterWrapper>
                <img src={group} alt="img" />
                <div className="Card_lavel">Quick</div>
                <div>
                    <h3>Sonic Surge</h3>
                    <span className="discountedPrice">₹1500</span>&nbsp;&nbsp;&nbsp;<span className="actualPrice">2999</span>
                    <div className="proceedBtn">Proceed</div>
                    <div className="addToCartBtn">Add to Cart</div>
                </div>
            </CardOuterWrapper>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const array = []
        for (let i = 0; i < 10; i++) {
            array.push(this.renderCard())
        }
        const userStory = {
            data: [
                {
                    id: "1009",
                    type: "story",
                    attributes: {
                        id: 1009,
                        title: "",
                        description: "",
                        location: null,
                        color: null,
                        is_active: false,
                        created_at: "2025-02-12T07:43:21.878Z",
                        updated_at: "2025-02-12T07:43:21.930Z",
                        seen: false,
                        creator_details: {
                            id: 1969,
                            full_name: "Sheena Pandey",
                            profile_url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa2REIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3bfeba51d6048e3ce85435f5284c83f2833e7d97/p-basket-of-50-red-roses-61671-m.jpg"
                        },
                        media: {
                            id: 18125,
                            filename: "aniestla-3Hsqx6BaQjQ-unsplash.jpg",
                            url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaVpHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0e4ef8bf54f4beb353417a1c90e4cf1e2781a2fb/aniestla-3Hsqx6BaQjQ-unsplash.jpg",
                            type: "image",
                            thumbnail: null
                        },
                        liked: false
                    }
                },
                {
                    id: "1010",
                    type: "story",
                    attributes: {
                        id: 1010,
                        title: "",
                        description: "",
                        location: null,
                        color: null,
                        is_active: false,
                        created_at: "2025-02-12T07:43:35.420Z",
                        updated_at: "2025-02-12T07:43:35.444Z",
                        seen: false,
                        creator_details: {
                            id: 1969,
                            full_name: "Sheena Pandey",
                            profile_url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa2REIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3bfeba51d6048e3ce85435f5284c83f2833e7d97/p-basket-of-50-red-roses-61671-m.jpg"
                        },
                        media: {
                            id: 18127,
                            filename: "pexels-brian-van-den-heuvel-339534-1313267.jpg",
                            url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaWhHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--eb1ef24b74d957ee966de656bed6d24dc973f796/pexels-brian-van-den-heuvel-339534-1313267.jpg",
                            type: "image",
                            thumbnail: null
                        },
                        liked: false
                    }
                },
                {
                    id: "1011",
                    type: "story",
                    attributes: {
                        id: 1011,
                        title: "",
                        description: "",
                        location: null,
                        color: null,
                        is_active: false,
                        created_at: "2025-02-12T07:43:45.995Z",
                        updated_at: "2025-02-12T07:43:46.035Z",
                        seen: false,
                        creator_details: {
                            id: 1969,
                            full_name: "Sheena Pandey",
                            profile_url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBa2REIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--3bfeba51d6048e3ce85435f5284c83f2833e7d97/p-basket-of-50-red-roses-61671-m.jpg"
                        },
                        media: {
                            id: 18129,
                            filename: "pexels-pixabay-51312.jpg",
                            url: "https://whatnotinlikeappphase1-285840-ruby.b285840.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaXBHIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--776bfc193de413c56e6f2462f2b4e90a3f68ce5f/pexels-pixabay-51312.jpg",
                            type: "image",
                            thumbnail: null
                        },
                        liked: false
                    }
                }
            ]
        }
        // Customizable Area End
        return (
            // Customizable Area Start
            <OuterMainContainer>
                <HeaderTagContainer>Creator Mall</HeaderTagContainer>
                <MainContainer>
                    <img className="bannerImg" src={CreatorBanner} alt="banner" />
                    <BannerOutline>
                        <MallDetailContainer>
                            <span className="mallName">City Mega Mall</span>
                            <span className="mallLocation">Mumbai, India</span>
                        </MallDetailContainer>
                        <LikeAndShareContainer>
                            <span>10K</span>
                            <img src={like} alt="like" width={20} height={20} />
                            <img src={Outlined} alt="heart" width={20} height={20} />
                            <img src={Share} alt="share" width={20} height={20} />
                        </LikeAndShareContainer>
                    </BannerOutline>
                </MainContainer>

                <StoriesHighlightsWrap>
                    <VisitMyMallContainer>Visit my mall</VisitMyMallContainer>
                    <InnerWrap>
                        <StoryOuter data-test-id="createNewStoryTestId" onClick={this.createStoryNav}>
                            <StoryNew>
                                <IconButton>
                                    <img src={AddGrayIc} alt="Edit" />
                                </IconButton>
                            </StoryNew>
                            <div>
                                <p>New</p>
                            </div>
                        </StoryOuter>
                        {userStory.data && userStory.data.map((item: StoryData, index: number) => {
                            return (
                                <>
                                    {
                                        <StoryContainer data-test-id={`openStoryTestID${index}`}
                                        >
                                            {item.attributes.media.type === "image" ?
                                                <Avatar src={item.attributes.media.url} data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                : <video src={item.attributes.media.url}
                                                    autoPlay={false}
                                                    width={"100%"}
                                                    height={"100%"}
                                                    muted={this.state.storyMuted}
                                                    playsInline />
                                            }
                                        </StoryContainer>
                                    }
                                </>
                            )
                        })}
                    </InnerWrap>
                </StoriesHighlightsWrap>

                <ProductHeading>Products</ProductHeading>
                <ProductListing>
                    {array}
                </ProductListing>
            </OuterMainContainer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const OuterMainContainer = styled(Box)({
    padding: "25px"
});

const HeaderTagContainer = styled(Box)({
    marginTop: "100px",
    marginBottom: "15px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#444444"
});

const CardOuterWrapper = styled(Box)({
    width: "193px",
    height: "auto",
    border: "1px solid #F1F1F1",
    borderRadius: "4px",
    cursor: "pointer",
    position: "relative",
    '& .Card_lavel': {
        position: "absolute",
        top: "0px",
        left: "0px",
        background: "black",
        color: "white",
        padding: "5px 15px",
        borderTopLeftRadius: "5px",
        borderBottomRightRadius: "5px",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "16px"
    },
    '& img': {
        width: "100%",
        height: "174px",
        top: "10px"
    },
    '& div': {
        padding: "0 10px"
    },
    '& div h3': {
        fontWeight: 500,
        fontSize: "18px",
        lineHeight: "27px"
    }, '& div .discountedPrice': {
        fontWeight: 700,
        fontSize: "16px",
        lineHeight: "24px",
        color: "black"
    },
    '& div .actualPrice': {
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#B6B6B6",
        textDecoration: "line-through"
    },
    '& .proceedBtn': {
        width: "100%",
        background: "#F5EA16",
        textAlign: "center",
        padding: "7px 0px",
        borderRadius: "30px",
        margin: "20px 0",
        cursor: "pointer",
        color: "black"
    },
    '& .addToCartBtn': {
        width: "100%",
        background: "#fff",
        textAlign: "center",
        padding: "7px 0px",
        borderRadius: "30px",
        margin: "20px 0",
        cursor: "pointer",
        color: "black",
        border: "1px solid black"
    }
});

const VisitMyMallContainer = styled(Box)({
    margin: "15px 0",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "black"
});

const MainContainer = styled(Box)({
    width: "100%",
    height: "auto",
    position: "relative",
    '& .bannerImg': {
        borderRadius: "5px",
        width: "100%",
        height: "234px"
    }
});

const BannerOutline = styled(Box)({
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#000000A1",
    position: "absolute",
    top: "185px",
    height: "50px",
    width: "100%",
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",

});

const MallDetailContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "baseline",
    width: "310px",
    '& .mallName': {
        fontWeight: 600,
        fontSize: "18px",
        lineHeight: "27px"
    },
    '& .mallLocation': {
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "18px"
    }
});

const LikeAndShareContainer = styled(Box)({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "150px",
    '& img': {
        cursor: "pointer"
    }
});

const StoriesHighlightsWrap = styled(Box)({
    margin: "20px 0"
});

const InnerWrap = styled(Box)({
    display: "flex",
    overflowX: "auto",
    paddingBottom: "20px"
});

const StoryOuter = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "30px",
    '& p': {
        color: "#444",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "1.5px"
    }
});

const StoryNew = styled(Box)({
    position: "relative",
    width: "72px",
    height: "72px",
    minWidth: "71px",
    backgroundColor: "#FBF7A2",
    borderRadius: "100%",
    border: "1px solid transparent",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    boxSizing: "border-box",
    alignItems: "center",
    flexDirection: "column",
    '& IconButton': {
        bottom: 0,
        right: 0
    }
});

const StoryContainer = styled(Box)({
    position: "relative",
    width: "72px",
    height: "72px",
    minWidth: "71px",
    background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #EB3AA7, #6649D9) border-box",
    borderRadius: "100%",
    border: "1px solid gray",
    marginRight: "30px",
    cursor: "pointer",
    '& .profile-photo': {
        width: "100%",
        height: "100%",
        borderRadius: "100%"
    }
});

const ProductHeading = styled(Box)({
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "23px",
    color: "black"
});

const ProductListing = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    margin: "20px 0"
});
// Customizable Area End