import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import StorageProvider from "../../../framework/src/StorageProvider";
// Customizable Area Start
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object | string;
  type: string;
}
interface ValidResponse {
  data: object;
  manufacturing_detail: object;
}
interface ValidResponseMessage {
  message: string;
}
export interface ErrorResponse {
  errors: [
    {
      token: string;
      Tasklist: string;
    }
  ]
}

export interface SubCategoriesData {
  id: string,
  type: string,
  attributes: {
    product_detail_specifications: {
      data: [
        {
          id: string,
          type: string,
          attributes: {
            id: 9,
            name: string,
            specification_type: string,
            specification_data: Array<string>
          }
        }
      ]
    }
  }
}
export interface ManufacurtingSubCategories {
  data: any
}
export interface ManufacturingPostData {
  catalogue_id: number,
  manufacturing_detail: {
    country: string,
    manufacture_details: string,
    packer_details: string,
    return_and_exchange: boolean,
    return_and_refund: boolean,
    manufactures_product_details_attributes: [{
      product_detail_specification_id: number,
      specification_data: string
    }],
    size_chart: string
  }
}

export interface ManufacturesProductDetails {
    id: number
    manufacturing_detail_id: number
    product_detail_specification_id: number
    specification_data: string
    created_at: string
    updated_at: string
}
export interface GetManufacturingDetails {
  manufacturing_detail: {
    data: {
      id: string,
      type: string,
      attributes: {
        country: string,
        manufacture_details: string,
        packer_details: string,
        return_and_exchange: boolean,
        return_and_refund: boolean,
        size_chart: string,
        manufactures_product_details: ManufacturesProductDetails[],
        size_chart_filename:string,
        size_chart_filesize:number
      }
    }
  }
}

interface StringKeyValue {
  slug: string;
  name: string;
}
const navigation = require("react-navigation");

export interface SnackBarIn {
  isOpen: boolean;
  isSeverity: 'success' | 'info' | 'warning' | 'error';
  isMessage: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: typeof navigation;
  classes: Record<string, string>;
  handleActiveStep: (step: number) => void;
  // Customizable Area End
}
export interface S {
  // Customizable Area Start
  sub_category: string;
  exchangeCheck: boolean;
  refundCheck: boolean;
  county: string;
  manufacturerDetails: string;
  packerDetails: string;
  storageSize: Array<string>;
  chartPicture: string | Blob | null;
  chartPictureName: string | null;
  chartPictureSize: number;
  countryError: boolean;
  manufacturerDetailsError: boolean;
  packerDetailsError: boolean;
  storageSizeError: boolean[];
  manufacturingProductDetails: ManufacurtingSubCategories;
  postApiHit: boolean;
  category_id: number
  storageSizeValidation: boolean
  countryList: StringKeyValue[]
  navigate:string;
  snackBarStates: SnackBarIn;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}


export default class ManufacturingDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postManufacturingApiCallId: string = "";
  getSubCategoriesDetails: string = "";
  getManufacturingDetailsDetailsApiCallId: string = "";
  getProductDetailsId: string = ""
  getCountryListApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      sub_category: "",
      exchangeCheck: false,
      refundCheck: false,
      county: "",
      manufacturerDetails: "",
      packerDetails: "",
      storageSize: [],
      chartPicture: "",
      chartPictureName: "",
      chartPictureSize: 0,
      countryError: false,
      manufacturerDetailsError: false,
      packerDetailsError: false,
      storageSizeError: [],
      manufacturingProductDetails: [] as unknown as ManufacurtingSubCategories,
      postApiHit: true,
      category_id: 0,
      storageSizeValidation: false,
      countryList: [],
      navigate:"",
      snackBarStates: {
        isOpen: false,
        isSeverity: "success",
        isMessage: ""
      },
      // Customizable Area End
    };
  }

  // Customizable Area Start

  async componentDidMount() {
    await setStorageData('currentStep', 4);
    const sub_category_id = await StorageProvider.get("sub_category_id");
    if (sub_category_id !== null) {
      this.setState({
        sub_category: sub_category_id,
      })
    }
    const array = window.location.pathname.split("/");
    const id = array[array.length - 1];
    if (array.length > 2) {
      this.getProductDetails(id)
    } else {
      this.getManufacturingDetailsResponse()
      this.getCategoryResponse();
    }
    this.getCountryList()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.responseSuccessDataKeyCall(apiRequestCallId, responseJson);
      }
      else if (this.isValidResponseManu(responseJson)) {
        this.responseSuccessCall(apiRequestCallId, responseJson);
      }

      if (apiRequestCallId === this.getProductDetailsId) {
        if (responseJson.data?.attributes !== null) {
          this.setState({
            sub_category: responseJson.data.attributes.sub_category.id
          }, () => { this.getManufacturingDetailsResponse(); })
        }
      }

      if (apiRequestCallId === this.getCountryListApiCallId) {
        if (responseJson.countries as Record<string, string>) {
          this.setState({
            countryList: Object.entries(responseJson.countries).map(([slug, name]) => ({ slug: String(slug), name: String(name) })),
          })
        }
      }
    }
    // Customizable Area End
  }

  handleCountryChange = (event: any, newValue: any) => {
    this.setState({county: newValue});
    this.setState({ countryError: false });
  }

  isValidResponse = (responseJson: ValidResponse) => {
    return responseJson && responseJson.data;
  };
  isValidResponseManu = (responseJson: GetManufacturingDetails) => {
    return responseJson && responseJson.manufacturing_detail;
  };
  apiCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("singupLogin");
    let { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: token ? token : ""
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  apiFormCall = (data: APIPayloadType) => {
    const token = localStorage.getItem("singupLogin");
    let { method, endPoint, body } = data;
    const header = {
      token: token ? token : ""
    };
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
        );
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  responseSuccessCall = async (apiRequestCallId: string, responseJson: ManufacturingPostData & GetManufacturingDetails) => {

    if (apiRequestCallId === this.getManufacturingDetailsDetailsApiCallId) {
      this.getManufacturingSuccessCall(responseJson);
    }

    if (apiRequestCallId === this.postManufacturingApiCallId) {
      if(this.state.navigate==="save"){
      this.postInventorySuccessCall("InventoryManagement");
      }else{
        this.postInventorySuccessCall("AddProductVariants");
      }
    }
  };

  responseSuccessDataKeyCall = async (apiRequestCallId: string, responseJson: ManufacurtingSubCategories) => {
    if (apiRequestCallId === this.getSubCategoriesDetails) {
      this.getManufacturingDetailsSuccessCall(responseJson);
    }
  };

  postInventorySuccessCall = (route: string) => {
    if (route === "InventoryManagement") {
      const pathName = window.location.pathname.split("/")[1];
      let messageType = pathName === "EditProduct" ? 'updated' : 'added'
      this.setState({
        snackBarStates: {
          isOpen: true,
          isSeverity: 'success',
          isMessage: `Product ${messageType} succesfully`
        }
      });
      setTimeout(() => {
        this.navigateToPage(route);
      }, 3000)
    } else {
      this.navigateToPage(route);
    }
  };

  navigateToPage = (route: string) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), route);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(to);
  };

  handleCloseSnack = () => {
    this.setState({
      snackBarStates: {
        isOpen: false,
        isSeverity: 'success',
        isMessage: ''
      }
    });
  };

  getManufacturingDetailsSuccessCall = (responseJson: ManufacurtingSubCategories) => {
    this.setState({
      manufacturingProductDetails: responseJson
    }, () => {
      let newArray: Array<string> = [];
      responseJson.data.attributes.product_detail_specifications.data.forEach(() => {
        newArray.push("");
      });
      this.setState({
        storageSizeError: Array.from({ length: this.state.storageSize.length }, () => false)
      })
      if (this.state.storageSize.length === 0) {
        this.setState({
          storageSize: newArray,
        });
      }
    });
  };


  handleExchangeCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ exchangeCheck: event.target.checked });
  };

  handleRefundCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ refundCheck: event.target.checked });
  };

  handleCountry = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      county: event.target.value,
      countryError: false,
    })
  };

  handleManufacturerDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      manufacturerDetails: event.target.value,
      manufacturerDetailsError: false,
    })
  };

  handlePackerDetails = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      packerDetails: event.target.value,
      packerDetailsError: false,
    })
  };

  handleStorageField = (event: React.ChangeEvent<{ value: string }>, index: number) => {
    const selectedValue = event.target.value;
    const updatedStorageSize = [...this.state.storageSize];
    updatedStorageSize[index] = selectedValue;
    this.setState({
      storageSize: updatedStorageSize
    })
  };

  handleStorageFieldSelect = (event: React.ChangeEvent<{ value: unknown }>, index: number) => {
    const selectedValue = event.target.value as string;
    const updatedStorageSize = [...this.state.storageSize];
    updatedStorageSize[index] = selectedValue;
    this.setState({
      storageSize: updatedStorageSize
    })
  }


  handleUploadChartPicture = (fileEvent: React.ChangeEvent<HTMLInputElement>) => {

    const files = fileEvent.target.files;
    if (files && files.length > 0) {
      this.setState({
        chartPicture: files[0],
        chartPictureName: files[0].name,
        chartPictureSize: files[0].size,
      });
    }
  };

  handleCancelUpload = () => {
    this.setState({
      chartPicture: null,
      chartPictureName: null,
      chartPictureSize: 0,
    });
  };



  submitDataApiCall = () => {
        this.getAllTaskListDataShow();      
  }

  handleManufacturingDetailsSubmit = (value: string) => {
    this.setState({ navigate: value });

    if (this.state.county.length === 0) {
      this.setState({ countryError: true });
    }

    if (this.state.manufacturerDetails.length === 0) {
      this.setState({ manufacturerDetailsError: true });
    }

    if (this.state.packerDetails.length === 0) {
      this.setState({ packerDetailsError: true });
    }
    const updatedStorageSizeError = this.state.storageSize.map(item => item === '');
    this.setState({ storageSizeError: updatedStorageSizeError });
    const error = updatedStorageSizeError.every(item => item === false);
  
    if (this.state.county.length !== 0 &&
        this.state.manufacturerDetails.length !== 0 &&
        this.state.packerDetails.length !== 0 && error) { 
        this.submitDataApiCall();
    }
  };
  
  

  getCategoryResponse = async () => {
    this.getSubCategoriesDetails = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getSubCategoriesEndpoint}/${this.state.sub_category}`,
      type: ""
    });
  };

  getManufacturingDetailsResponse = async () => {
    const catalogueID = await getStorageData("catalogueID");
    this.getManufacturingDetailsDetailsApiCallId = this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.getManufacturingApiEndPoint + catalogueID}`,
      type: ""
    });
  };

  getManufacturingSuccessCall = (responseJson: GetManufacturingDetails) => {
    if (responseJson.manufacturing_detail.data !== null) {
      if (responseJson.manufacturing_detail.data.attributes.country === "") {
        this.setState({
          postApiHit: true
        },()=> {this.getCategoryResponse();})
      } else {
        const arrayState:any = [];
        responseJson.manufacturing_detail.data.attributes.manufactures_product_details.forEach((item, index) => {
          arrayState[index] = item.specification_data;
        });
        this.setState({
          exchangeCheck: responseJson.manufacturing_detail.data.attributes.return_and_exchange,
          refundCheck: responseJson.manufacturing_detail.data.attributes.return_and_refund,
          county: responseJson.manufacturing_detail.data.attributes.country,
          manufacturerDetails: responseJson.manufacturing_detail.data.attributes.manufacture_details,
          packerDetails: responseJson.manufacturing_detail.data.attributes.packer_details,
          storageSize: arrayState,
          postApiHit: false,
          chartPictureName: responseJson.manufacturing_detail.data.attributes.size_chart_filename,
          chartPictureSize: responseJson.manufacturing_detail.data.attributes.size_chart_filesize,
        },()=>{this.getCategoryResponse();})
      }
    }
  };


  getAllTaskListDataShow = async () => {
    const catalogueID = await getStorageData("catalogueID");  
    let formdata = new FormData();
    formdata.append('catalogue_id', catalogueID);
    formdata.append('manufacturing_detail[country]', this.state.county);
    formdata.append('manufacturing_detail[manufacture_details]', this.state.manufacturerDetails);
    formdata.append('manufacturing_detail[packer_details]', this.state.packerDetails);
    formdata.append('manufacturing_detail[return_and_exchange]', String(this.state.exchangeCheck));
    formdata.append('manufacturing_detail[return_and_refund]', String(this.state.refundCheck));

    if (this.state.chartPicture !== null && typeof this.state.chartPicture !=="string"  && this.state.chartPictureName) {
        formdata.append('manufacturing_detail[size_chart]', this.state.chartPicture, this.state.chartPictureName);
    }
    
    if (this.state.storageSize.length > 0) {
        this.state.storageSize.forEach((item, index) => {
            formdata.append('manufacturing_detail[manufactures_product_details_attributes][][product_detail_specification_id]',
                String(this.state.manufacturingProductDetails?.data.attributes?.product_detail_specifications.data[index]?.id));
            formdata.append('manufacturing_detail[manufactures_product_details_attributes][][specification_data]', item);
        });
    }

    this.postManufacturingApiCallId = this.apiFormCall({
      method: this.state.postApiHit ? configJSON.exampleAPiMethod : configJSON.exampleAPiMethodPut,
      endPoint: configJSON.postManufacturingApiEndPoint,
      body: formdata,
      type: "formdata"
    });
  };

  getProductDetails=async(catalogueID:string)=>{      
    const singupLogin =  await getStorageData("singupLogin");
    const endPoint = `${configJSON.catalogueEndpoint}/${catalogueID}`
    const headers = {
        token: singupLogin,

      };
    const getProductDetailsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getProductDetailsId = getProductDetailsMsg.messageId;
  
    getProductDetailsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    getProductDetailsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      getProductDetailsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
  
    runEngine.sendMessage(getProductDetailsMsg.id, getProductDetailsMsg);

  }

  getCountryList = () => {
    const header = {
      "Content-Type": "application/json"
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.countryList);

    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType);

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}