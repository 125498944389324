Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";
exports.fullNameError = "Your Name is required";
exports.phoneError = "Please Enter Valid Mobile number";
exports.emailError = "Enter valid email i.e. johndoe@gmail.com";
exports.confirmPwdError = "Password & Re-enter Password should be same";
exports.confirmPasswordError = "Password & Confirm Password should be same";
exports.unmatchedPwdError = "Passwords do not match"
exports.otpError = "Please Enter Valid OTP";
exports.placeHolderP = "* * * * * * * * * *"
exports.confirmPassword = "* * * * * * * * * *"
exports.signUp = "Sign Up"
exports.verificationCodeTitle = "Enter your verification code"

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password must contain 1 Uppercase, 1 Lowercase, 1 number & 1 special character.";
exports.enterInfo = "Enter the information to Log in to your Flixoo account"
exports.enterSignUpInfo = "Enter the information to register on Flixoo.";
exports.basicInfo = "Basic Information";
exports.selectDob = "Please select your Date of Birth";
exports.instagramPlaceholder = "Paste your Instagram link here";
exports.youtubePlaceholder = "Paste your Youtube link here";
exports.additionalInfo = "Additional Information";
exports.creatorNamePlaceHolder = "Enter your store creator name";
exports.register = "Register";
exports.gender = "Gender";
exports.category = "Category";
exports.male = "Male";
exports.female = "Female";
exports.notReveal = "Don't want to reveal";
exports.signUpCreator = "Signup as Creator";
exports.businessNamePlaceholder = "Enter your store/brand/business name"
exports.fullNamePlaceholder = "John Doe"
exports.userNamePlaceholder = "John Doe__01"
exports.selectAnyone = "select_any_one"
exports.selectAntoneLabel = 'Select any one'
exports.mobileNumberLabel =  "Mobile number"
exports.errorTitle = "Error";
exports.email = "Johnbusiness@gmail.com"

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.bankDetails = "Bank details";
exports.bankAccountPlaceholder = "6748837569914649"
exports.AccountHolderNamePlaceholder = "John doe"
exports.bankNamePlaceholder = "HSBC"
exports.ifscPlaceholder = "HSBC00934"
exports.businessDetails = "Business details"
exports.returnAndPickUp = "Return & pick-up Address"
exports.addressLineOnePlaceholder = "242 Celestine Junctions"
exports.addressLineTwoPlaceholder = "Enter address line 2"
exports.pincodePlaceholder = "714953"
exports.statePlaceholder = "Karnataka"
exports.cityPlaceholder = "Bangalore"
exports.countryPlaceholder = "India"
exports.gstinOrPanPlaceholder = "ABCDE1234F"
exports.emailPlaceholder = "example@email.com"
exports.businessLicense = "ABCTY1234D"
exports.editYourMobileNumber = "Edit your Mobile number"
exports.ValidMobileNumberErrorText    = "Please enter a valid Mobile number."
exports.getOtp = "Get OTP"

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";
exports.signUpEndpoint = "account/accounts";
exports.availableEndpoint = "account_block/available_usernames";
exports.categoryEndpoint = "bx_block_categories/categories";
exports.subCategoryEndpoint = "bx_block_categories/sub_categories";
exports.businessDetailsEndpoint = "bx_block_profile/business_details";
exports.bankDetailsEndpoint = "bx_block_custom_form/bank_detail";
exports.addressEndpoint = "bx_block_address/address";

exports.otpEndpointEmail = "account/accounts/email_confirmation";
exports.otpEndpointMobile = "account/accounts/sms_confirmation";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";
exports.apiEndPointGetCountryCodes = "account/accounts/country_code_and_flag";
exports.apiGetCountryCodesType = "GET";
exports.validationApiMethodType = "GET";
exports.resendotpEndpointMobile="account/accounts/send_otp"
exports.resendotpEndpointEmail="send_email_otp"
exports.sellerSignUpEndPoint = "account/accounts"
exports.errorMessage = "Password is required"
exports.continueText = "Continue"; 
exports.regSbmtTxt = "Registration Submitted";
exports.regSbmtSubTxt = "Your creator profile registration has been successfully submitted. You will be able to access the application once the admin verifies the account";
exports.errorMessageConfrim = "Confirm Password is required"
exports.sellerMobileOtpVerifyEndPoint = "account/accounts/sms_confirmation"
exports.editMobileEmailEndPoint = "account_block/update_seller_profile"
exports.sellerTermsAndConditonEndPoint = "terms_and_condition?condition_for=seller"
exports.sellerPrivacyPolicyEndPoint = "privacy_policy?policy_for=seller"
exports.buyerPrivacyPolicyEndPoint = "terms_and_condition?condition_for=buyer"
exports.buyerPrivacyPolicysEndPoint = "privacy_policy?policy_for=buyer"
exports.creatorInfoEndpoint = "account_block/update_creator_profile";
exports.socialGoogleSignup = "account_block/google_signup"

exports.errorCapitalLetter = "Password must contain atleast 1 uppercase letter"
exports.errorSmallLetter = "Password must contain atleast 1 lowercase letter"
exports.errorDigit = "Password must contain atleast 1 digit"
exports.errorSymbol = "Password must contain atleast 1 special character"
exports.errorChar = "Passwords should be a minimum of 8 characters"

exports.googleLoginApiEndPoint = "/account_block/google_login_or_signup"
exports.accountVerifyEndPoint = "account_block/accounts/activate_account"


exports.alreadyAccount = "Already have an account?";
exports.countryList = "bx_block_address/get_country_list"
exports.stateList = "bx_block_address/get_state_list"
exports.cityList = "bx_block_address/get_city_list"

// Customizable Area End
