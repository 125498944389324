import React from "react";
// Customizable Area Start
import { Box, Typography, styled, Button } from "@material-ui/core";
import { arrowLeftIc, defaultAvatar, FlixooIcon, instagramIcon, likeIcon, shareNewIcon, youtubeIcon } from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import CreatorMarketPlaceController, { Props } from "./CreatorMarketPlaceController";
import { arrowDownIcon } from "../../postcreation/src/assets";
import { searchModalIcon } from "../../LiveStreaming/src/assets";
import '../assets/css/styleSeller.css'
// Customizable Area End


export default class CreatorMarketPlace extends CreatorMarketPlaceController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderFilterView = () => {
        return (
            <Box className="filterBoxContainer">
                <Box className="filterBox">
                    <Typography className="filterTxt">Filter:</Typography>
                    <Box className="filterStatusBox">
                        <Box className="filterInputBox">
                            <Typography className="filterTypeTxt">Location</Typography>
                            <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                        </Box>
                        <Box className="filterInputBox">
                            <Typography className="filterTypeTxt">Gender</Typography>
                            <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                        </Box>
                        <Box className="filterInputBox">
                            <Typography className="filterTypeTxt">Followers</Typography>
                            <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                        </Box>
                        <Box className="filterInputBox">
                            <Typography className="filterTypeTxt">Speciality</Typography>
                            <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                        </Box>
                        <Box className="filterInputBox">
                            <Typography className="filterTypeTxt">Price</Typography>
                            <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                        </Box>
                    </Box>
                </Box>
                <Box className="searchBoxContainer">
                    <SearchStyleWrapper className="inventorySearch">
                        <img src={searchModalIcon} alt="search" />
                        <input type="text" placeholder={"Search"} data-test-id="inventorySearchTestID" />
                    </SearchStyleWrapper>
                </Box>
            </Box>
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div service-create-main-div">
                <div className="dashboard-outer" data-test-id="creatorProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="creatorSidebar"
                            closeModal={() => this.toggleDrawer()}
                        />
                        <CreatorMarketPlaceWrapper>
                            <Box className="marketPlaceHeaderBox">
                                <Box className="titleBox">
                                    <button className="backIconButton">
                                        <img src={arrowLeftIc} alt="left arrow" />
                                    </button>
                                    <Typography className="marketPlaceTitle">Creator Market Place</Typography>
                                </Box>
                            </Box>

                            <Box className="middleHeaderBox">
                                <Box className="socialDropDown">
                                    <Typography className="socialDropTxt">Instagram</Typography>
                                    <img src={arrowDownIcon} alt="left arrow" className="downIcon" />
                                </Box>
                            </Box>

                            {this.renderFilterView()}

                            <Box className="creatorMainContainer">
                                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((cardView, cardIdx) => {
                                    return (
                                        <Box className="cardMainBox" key={cardIdx} >
                                            <Box className="imageBox">
                                                <Box className="topRightBox">
                                                    <Box className="iconMainContainer">
                                                        <Box className="iconBox pointer">
                                                            <img src={likeIcon} alt="heartIcon" />
                                                        </Box>
                                                        <Box className="iconBox pointer">
                                                            <img src={shareNewIcon} alt="shareIcon" />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <img src={defaultAvatar} alt="" className="creatorImg" data-test-id={`creatorCardTestId${cardIdx}`} onClick={()=> this.navigateToPath("CreatorProfile")} />

                                                <Box className="bottomBox">
                                                    <Box className="priceBox">
                                                        <Typography className="priceText">
                                                            Marketing Price : <span className="boldTxt">₹100</span>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            <Box className="creatorInfoBox" >
                                                <Box className="creatorDetail">
                                                    <Typography className="upperText">
                                                        Merry Storm
                                                    </Typography>
                                                    <Typography className="upperText">
                                                        870K
                                                    </Typography>
                                                </Box>
                                                <Box className="creatorDetail lowerBox">
                                                    <Typography className="lowerText">
                                                        Mumbai, India
                                                    </Typography>
                                                    <Typography className="lowerText">
                                                        Followers
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            <Box className="creatorSocialBox">
                                                <Box className="socialIconBox">
                                                    <img src={youtubeIcon} alt="" className="socialIcons" />
                                                    <img src={instagramIcon} alt="" className="socialIcons" />
                                                    <img src={FlixooIcon} alt="" className="socialIcons" />
                                                </Box>
                                                <Box className="messageBtnBox">
                                                    <Button className="messageButton" onClick={()=> this.navigateToPath("SellerChat")}>Message</Button>
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </CreatorMarketPlaceWrapper>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CreatorMarketPlaceWrapper = styled(Box)({
    paddingTop: "33px",
    paddingLeft: "23px",
    paddingRight: "54px",
    width: "100%",
    "& .titleBox": {
        display: "flex",
        alignItems: "center",
        height: "24px"
    },
    "& .marketPlaceTitle": {
        fontWeight: 600,
        fontSize: "16px",
        color: "#444444CC"
    },
    "& .filterBoxContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap",
        gap: "20px"
    },
    "& .filterBox": {
        display: "flex",
        alignItems: "center",
    },
    "& .filterStatusBox": {
        display: "flex",
        alignItems: "center",
        gap: "15px",
        flexWrap: "wrap",
    },
    "& .filterTxt": {
        color: "#444444",
        fontSize: "14px",
        fontWeigt: 400,
        marginRight: "10px"
    },
    "& .filterInputBox": {
        border: "1px solid #00000080",
        borderRadius: "50px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "112px",
        padding: "4px 14px"
    },
    "& .filterTypeTxt": {
        fontWeight: 500,
        fontSize: "14px",
        color: "#64748B"
    },
    "& .downIcon": {
        width: "24px",
        height: "24px",
    },
    "& .creatorMainContainer": {
        marginTop: "40px",
        display: "flex",
        gap: "30px",
        flexWrap: "wrap",
        maxHeight: "900px",
        overflowX: "auto",
        scrollbarWidth:"none",
        "@media(max-width:492px)": {
            display: "flex",
            justifyContent: "center"
        },
    },
    "& .creatorMainContainer::-webkit-scrollbar ": {
        display: "none",
      },
    "& .cardMainBox": {
        maxWidth: "190px",
        height: "346px",
        WebkitAnimation: 'fadeIn 1s',
        MozAnimation: 'fadeIn 1s',
        OAnimation: 'fadeIn 1s',
        msAnimation: 'fadeIn 1s',
        animation: 'fadeIn 1s',
    },
    "& .creatorImg": {
        borderRadius: "10px",
        cursor:"pointer",
    },
    "& .creatorInfoBox": {
        marginTop: "9px"
    },
    "& .creatorDetail": {
        display: "flex",
        justifyContent: "space-between"
    },
    "& .lowerText": {
        fontSize: "10px",
        fontWeight: 400,
        color: "#2E2E2E",
        lineHeight: "16px"
    },
    "& .upperText": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#2E2E2E",
        lineHeight: "16px"
    },
    "& .creatorSocialBox": {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "8px",
        alignItems: "center"
    },

    "& .socialIcons": {
        width: "20px",
        marginRight: "6px",
        cursor: "pointer"
    },
    "& .pointer": {
        cursor: "pointer"
    },
    "& .messageButton": {
        width: "79px",
        textTransform: "none",
        border: "1px solid yellow",
        fontSize: "12px",
        fontWeight: 500,
        borderRadius: "50px",
        padding: "3px 16px"
    },
    "& .middleHeaderBox": {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: "20px"
    },
    "& .socialDropDown": {
        display: "flex",
        justifyContent: "center"
    },
    "& .socialDropTxt": {
        fontWeight: 600,
        fontSize: "16px"
    },
    "& .imageBox": {
        position: "relative",
    },
    "& .bottomBox": {
        position: "absolute",
        bottom: "0px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "43px",
        background: "#1313134A",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
    },
    "& .priceText": {
        fontWeight: 500,
        fontSize: "12px",
    },
    "& .boldTxt": {
        fontWeight: 600
    },
    "& .priceBox": {
        maxWidth: "164px",
        height: "25px",
        backgroundColor: "#BAFFBE",
        padding: "3px 16px",
        borderRadius: "4px"
    },
    "& .topRightBox": {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    "& .iconMainContainer": {
        display: "flex",
        gap: "8px"
    },
    "& .iconBox": {
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#00000099",
        borderRadius: "50px"
    },
    "& .backIconButton": {
        border: "none",
        outline: "none",
        background: "white",
        padding: "0px",
        marginRight: "16px"
    },
});

const SearchStyleWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "12px",
    gap: "12px",
    borderRadius: "50px",
    border: "1px solid #CAC8C8",
    "&.inventorySearch": {
        maxWidth: "351px",
        width: "100%",
        height: "45px",
    },
    "& img": {
        cursor: "pointer"
    },
    "& input": {
        border: "none",
        outline: "none",
        maxWidth: "351px",
        width: "100%",
    },
});
// Customizable Area End