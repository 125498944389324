import React, { Component } from 'react';
import { muteButton, unMuteButton } from '../../blocks/landingpage/src/assets';
import { StoryUploaders } from './CustomStoryListing.web';

interface StoryListingProps {
  isMute: StoryUploaders;
}

interface State {
  isMuted: boolean;
}

class MuteStoryComponent extends Component<StoryListingProps, State> {
  constructor(props: StoryListingProps) {
    super(props);

    this.state = {
      isMuted: false,
    };
  }

  handleMuteToggle = () => {
    this.setState(
      (prevState) => ({ isMuted: !prevState.isMuted }),
      () => {
        this.muteAllMediaElements(this.state.isMuted);
      }
    );
  };

  muteAllMediaElements = (isMuted: boolean) => {
    const mediaElements = document.querySelectorAll('audio, video');
    mediaElements.forEach((media) => {
      (media as HTMLMediaElement).muted = isMuted;
    });
  };

  render() {
    return (
      <div onClick={this.handleMuteToggle} className="muteBox">
        <img src={this.state.isMuted ? muteButton : unMuteButton } alt="mute_button" />
      </div>
    );
  }
}

export default MuteStoryComponent;