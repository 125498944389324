import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config");
export const baseURL = require("../../../framework/src/config.js").baseURL;
const navigation = require('react-navigation')
// Customizable Area End

// Customizable Area Start
export interface StoryData {
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string
    description: string
    location: string | null,
    color: string | null,
    is_active: boolean,
    created_at: string,
    updated_at: string,
    seen: boolean,
    creator_details: {
      id: number,
      full_name: string,
      profile_url: string,
    },
    media: {
      id: number,
      filename: string,
      url: string,
      type: string,
      thumbnail: string | null
    },
    liked: boolean
  }
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  storyMuted: boolean;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreatorMallController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      storyMuted: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount() {
    const creatorToken = await getStorageData("creatorLogin");
    creatorToken && this.setState({ token: creatorToken });
  };

  createStoryNav = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateStory");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };
  // Customizable Area End
}