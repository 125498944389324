import React from "react";

import {
    // Customizable Area Start
    Box,
    Button,
    Typography,
    InputAdornment,
    IconButton,
    Checkbox,
    Grid,
    TextField,
    FormControlLabel,
    Link,
    InputLabel
    // Customizable Area End
} from "@material-ui/core";


// Customizable Area Start
import { applogo, indialogo, call_icon, mask_group, checkbox, checkboxFilled } from "./assets";
import { createTheme, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End

import EmailAccountLoginController, {
    Props, configJSON,
} from "./EmailAccountLoginController";
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";

class EmailAccountLogin extends EmailAccountLoginController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;

        // Customizable Area End
        return (
            // Customizable Area Start
            <Box justifyContent="center" alignItems="center" style={{ backgroundColor: 'black',position: 'relative', minHeight: '100%' }}>
                <Grid container style={{paddingBottom: '50px'}}>
                    <Grid item xs={12} md={5} style={{ margin: '45px 0', padding: '0 20px' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                            }}
                        >
                            <Box>
                                <img src={applogo} alt="" />
                                <Typography variant="h6" style={{ color: 'rgb(256,256,256,1)' }} >{'Live shopping & market place'}</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} 
                        style={{ padding: '0 20px', margin: '45px 0', minHeight: '80%' }} >
                        <Box
                            sx={{
                                border: '1px solid #fff',
                                borderRadius: '20px',
                                position: 'relative',
                                zIndex: 1,
                                padding: '30px',
                                p: 3,
                                width: '100%',
                                maxWidth: '660px',
                                margin: '0 auto',
                            }}
                        >
                            <Typography variant="h4" style={{
                                textAlign: "center",
                                marginTop: '5px',
                                lineHeight: '1',
                                letterSpacing: '0px',
                                color: "rgba(255, 255, 255, 1)",
                                fontSize: '32px',
                                fontWeight: 600,
                            }}>
                                Welcome
                            </Typography>
                            <Typography style={{
                                textAlign: "center",
                                fontSize: '18px',
                                fontWeight: 400,
                                maxWidth: '422px',
                                width: '100%',
                                margin: '10px auto 0',
                                color: "rgba(255, 255, 255, 0.6)",
                                lineHeight: '1.5',
                                letterSpacing: '0px',
                            }}>Enter the information to log in to your Flixoo account</Typography>
                            <form data-test-id="sellerloginform" onSubmit={(e) => this.checkValidationEmailLogin(e)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} style={{marginTop: '25px'}}>
                                        <TextField
                                            label="Username/ Email Id"
                                            variant="outlined"
                                            fullWidth
                                            placeholder={configJSON.placeHolderEmail}
                                            onChange={this.handleEmailUserChange}
                                            data-test-id="emailUserText"
                                            value={this.state.sellerEmailUser}
                                            name="loginEmail"
                                            type="text"
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { color: "rgba(255, 255, 255, 0.5)" },
                                            }}
                                            className={classes.customTextField}
                                        />
                                        {this.state.sellerEmailUserError !== "" && (
                                            <div className="red-text">{this.state.sellerEmailUserError}</div>
                                        )}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <TextField
                                            label="Password"
                                            placeholder={configJSON.placeHolderPassword}
                                            onChange={this.handlePassowrdChange}
                                            value={this.state.sellerPassword}
                                            name="loginPass"
                                            data-test-id="sellerPasswordText"
                                            variant="outlined"
                                            fullWidth
                                            type={this.state.showPassword ? '' : 'password'}
                                            InputLabelProps={{
                                                shrink: true,
                                                style: { color: 'rgba(255, 255, 255, 0.5)' },
                                            }}
                                            className={classes.customTextField}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton style={{ color: 'rgb(255,255,255,1)' }}
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                        >
                                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff /> }
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {this.state.sellerPasswordError !== "" && (
                                            <div className="red-text">{this.state.sellerPasswordError}</div>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <div className={classes.displayFlexBetween}>
                                                    <Box sx={{ display: 'flex', gridGap: '10px' }} className={classes.displayFlexAlignCenter}>
                                                        <Checkbox
                                                            color="primary"
                                                            name="checkedRememberMe"
                                                            checked={this.state.isChecked}
                                                            onChange={this.handleIsChecked}
                                                            value={this.state.isChecked}
                                                            data-test-id="rememberMeCheck"
                                                        />
                                                        <InputLabel className={`${classes.whiteText} ${classes.rememberMeContainer}`}>
                                                            Remember me
                                                        </InputLabel>
                                                    </Box>
                                                </div>
                                            </Grid>


                                            <Grid item xs={6} style={{ textAlign: "right", marginTop: '14px' }}>
                                                <Link href="SellerForgotPassword" variant="body2">
                                                    <Typography style={{ color: "rgba(255, 255, 255, 1)", fontSize: '14px', fontWeight: 500, fontFamily: 'Poppins !important', lineHeight: '1', textDecoration: 'underline' }}> Forgot Password?</Typography>
                                                </Link>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Button type="submit" data-test-id="sellerEmailLogin" variant="contained" style={{
                                            backgroundColor: "rgba(245, 234, 22, 1)", color: "#444", height: "56px", textTransform: 'none',
                                            borderRadius: '35px', marginBottom: '10px', marginTop: '10px',
                                            fontSize: '16px', fontWeight: 600, fontFamily: 'Poppins !important'
                                        }} fullWidth >
                                            {configJSON.labelTitle}
                                        </Button>

                                    </Grid>

                                    <Box className={classes.horizontalLine} style={{
                                        marginTop: '5px',
                                        width: '100%',
                                        margin: '12px'
                                    }}>
                                        <p>Or</p>
                                    </Box>

                                    <Grid item xs={12} style={{ margin: '20px 0' }}>
                                        <Button
                                            variant="outlined"
                                            style={{
                                                color: "#444444",
                                                height: "56px",
                                                borderRadius: "35px",
                                                width: "100%",
                                                textTransform: 'none',
                                                borderColor: "#F5EA16"
                                            }}
                                            data-test-id="mobile-number-navigate"
                                            onClick={this.navigateMobileNumber}
                                        >
                                            <Box style={{ display: 'flex', alignItems: 'center', height: '56px' }}>
                                                <img src={call_icon} alt="Call Icon" style={{ marginRight: '10px' }} />
                                                <Typography style={{ color: "rgba(255, 255, 255, 1)", fontWeight: 600, fontFamily: 'Poppins !important', fontSize: '16px', fontStyle: 'normal', lineHeight: 'normal' }}> Log in with mobile number</Typography>
                                            </Box>
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Typography style={{
                                    textAlign: "center",
                                    marginTop: '20px',
                                    color: "rgba(255, 255, 255, 1)",
                                    fontSize: '15px',
                                    fontWeight: 500,
                                    lineHeight: '1',
                                    letterSpacing: '0px',

                                }}>
                                    Don't have an account 
                                    <Link href="/SellerSignUp" style={{ color: "rgba(245, 234, 22, 1)", textDecoration: 'underline', marginLeft: '6px' }}>
                                         Sign Up
                                    </Link>
                                </Typography>

                                <Typography style={{ textAlign: "center", marginTop: "15px", display: 'flex', alignItems: 'center', justifyContent: 'center', color: "rgba(255, 255, 255, 0.6)", fontSize: '13px' }}>
                                    <img src={indialogo} alt="" height="16px" style={{ marginRight: '10px', fontSize: " small" }} />
                                    Made in Bharat
                                </Typography>
                            </form>
                        </Box>
                    </Grid>

                </Grid>
                <Box style={{ width: '100%', 
                            position: 'absolute', 
                            left: '0', 
                            right: '0', 
                            bottom: '0' }}>
                    <img src={mask_group} alt="Mask Group" style={{ width: '100%', height: 'auto' }} />
                </Box>
                <LoginFailModalWeb 
                    displayPopup={this.state.emailModal} 
                    closeErrorPopup={this.emailModalClose} 
                    titleMessage={'Invalid Credentials'}
                    errorMessage={this.state.modalMessage} />
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = (theme: any) => ({

    customTextField: {
        marginTop: '20px',
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "rgba(255, 255, 255, 0.50)",
                borderRadius: "10px",
            },
            "&.Mui-focused fieldset": {
                borderColor: "rgba(255, 255, 255, 0.50)",
            },
            "&:hover fieldset": { borderColor: "rgba(255, 255, 255, 0.50)" },
            '& .MuiInputBase-input::placeholder': {
                color: '#ffffff',
                opacity: 1,
                fontSize: '14px'
            },
        },
        "& label": {
            color: "rgb(255, 255, 255)",
        },
        "& input": { color: "rgb(255, 255, 255)" },
    },
    horizontalLine: {
        width: '100%',
        height: '1px',
        position: 'relative' as const,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        '& p': {
            marginTop: '-9px',
            margin: 'auto',
            padding: '0px 7px',
            fontSize: '12px',
            textAlign: 'center',
            backgroundColor: 'black',
            position: "absolute" as const,
            left: '47%' as const,
            lineHieght: '18px',
            color: 'rgba(255, 255, 255, 0.5)',
            postion: "absolute" as const,
        }
    },
    displayFlexAlignCenter: {
        display: 'flex',
        alignItems: 'center',
        "& .MuiCheckbox-root": {
            color: "white",
            background: "white",
            height: "17px",
            width: "17px",
            marginRight: '12px'
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16",
            background: "black"
        },
    },
    rememberMeContainer: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 'larger'
    },
    whiteText: {
        color: '#ffffff',
    },
    displayFlexBetween: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '10px',
        "& .MuiCheckbox-root": {
            color: "white",
            padding: '0px',
            backgroundColor: '#ffffff',
            width: '17px',
            height: '17px',
            borderRadius: '5px',
        },
        "& .Mui-checked.MuiCheckbox-root": {
            color: "#F5EA16"
        }
    },
});

export default withStyles(styles)(EmailAccountLogin);
export { EmailAccountLogin }
// Customizable Area End
