import React from "react";
// Customizable Area Start
import { IconButton, Badge, Modal } from "@material-ui/core";
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { calendarIc, notificationIcon, hamburgerIc } from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover'
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";
import ServiceOfferedController, { Props, ServiceData } from "./ServiceOfferedController.web";
import Loader from "../../../components/src/Loader.web";
import { editIcon, deleteIcon } from "../../InventoryManagement3/src/assets";
// Customizable Area End


export default class ServiceOffered extends ServiceOfferedController {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        // Customizable Area Start
        const allRead = this.state.allNotifications?.every(notification => notification.attributes.is_read);
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div service-offered-main-div">
                <Loader loading={this.state.isLoading} />
                <div className="dashboard-outer" data-test-id="creatorProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="creatorSidebar"
                            closeModal={() => this.toggleDrawer()}
                        />
                        <div className="right-outer">
                            <div className="right-inner">
                                <div className="title-wrap">
                                    <div className="left-wrap">
                                        <span className="title">Service offered</span>
                                    </div>
                                    <div className="right-wrap">
                                        <div className="btn-wrap">
                                            <div className="account-list" data-test-id="openAccountListContainer" onClick={this.OpenAccountList}>
                                                <div className="account-title">{"dsfsdfds"}</div>
                                                <div className="account-list-icon">{this.state.accountListOpen ? <ExpandLess /> : <ExpandMore />}</div>
                                            </div>
                                            <Popover
                                                className="account-list-popover"
                                                open={this.state.accountListOpen}
                                                anchorEl={this.state.accountListAnchorEl}
                                                data-test-id="closeAccountListTestId"
                                                onClose={() => this.CloseAccountList()}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                            </Popover>
                                            <IconButton className="icon-btn">
                                                <img src={calendarIc} alt="calendar" />
                                            </IconButton>
                                            <IconButton
                                                className="icon-btn"
                                                onClick={this.handleNotificationBoxToggle}
                                                data-test-id="creatorNotificationIcon"
                                            >
                                                <Badge color="secondary" variant={!allRead ? "dot" : "standard"}>
                                                    <img src={notificationIcon} alt="notification" />
                                                </Badge>
                                            </IconButton>

                                            <IconButton
                                                className="icon-btn hamburger-btn"
                                                data-test-id="creatorHamburger-btn"
                                                onClick={() => this.toggleDrawer()}
                                            >
                                                <img src={hamburgerIc} alt="hamburger" />
                                            </IconButton>
                                        </div>
                                        <>
                                            {this.state.isNotificationBoxOpen && (
                                                <SellerNotificationModal
                                                    data-test-id="sellerNotificationModal"
                                                    allNotifications={this.state.allNotifications}
                                                    closeNotificationBox={this.handleNotificationBoxToggle}
                                                    selectedTab={this.state.selectedTab}
                                                    handleTabChange={this.handleTabChange} />
                                            )}
                                        </>
                                    </div>
                                </div>
                                {
                                    this.state.allServices && this.state.allServices.map((item: ServiceData) => {
                                        return (
                                            <div className="service-card">
                                                <div className="file-edit-wrap">
                                                    <div className="service-card__title">{item.attributes.name}</div>
                                                    <div style={{ display: "flex"}}>
                                                        <img src={editIcon} alt="edit" height={25} data-test-id="editServiceTestId" onClick={() => this.handleEdit(item.id)} />
                                                        <img src={deleteIcon} alt="delete" height={25} style={{ marginLeft: "10px" }} data-test-id="deleteServiceTestId" onClick={() => this.handleDeleteService(item.id)} />
                                                    </div>
                                                </div>
                                                <div style={{padding: "16px"}}>
                                                    <div className="service-card__content">{item.attributes.description}</div>
                                                    <div className="service-card__meta">
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_post}</div>
                                                            <div>Posts</div>
                                                        </div>
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_story}</div>
                                                            <div>Stories</div>
                                                        </div>
                                                        <div className="service-card__action">
                                                            <div>{item.attributes.no_of_live_collaboration}</div>
                                                            <div>Life collabs</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                <button
                                    type="button"
                                    data-test-id="createServiceTestId"
                                    className="button-create-service"
                                    disabled={this.state.allServices.length >= 5}
                                    onClick={this.navigateCreateService}
                                >
                                    Create Service
                                </button>

                                <Modal
                                    open={this.state.isDeleteModalOpen}
                                    onClose={this.closeDeleteModal}
                                    className="delete-modal"
                                >
                                    <div className="confirmation-modal">
                                        <h3>Are you sure you want to delete this service?</h3>
                                        <div className="confirmation-buttons">
                                            <button onClick={this.handleDeleteConfirmed} data-test-id="confirmDeleteTestId" className="confirm-btn">Yes, Delete</button>
                                            <button onClick={this.closeDeleteModal} data-test-id="closeModalTestId" className="cancel-btn">Cancel</button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End