Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisedOrderStatus";
exports.labelBodyText = "CustomisedOrderStatus Body";
exports.getBuyerOrderListingApiEndPoint = "bx_block_productdescription/bargain_and_on_order_request_list";

exports.btnExampleTitle = "CLICK ME";
exports.order = "Order"
exports.onHold = "On Hold"
exports.productShipped ="Product shipped"
exports.orderCanceledBySellerText = "Order Cancelled"
exports.onhold = "On hold"
exports.readyToship = "Ready to ship"
exports.readyTopickup = "Ready to pickup"
exports.pending = "Pending"
exports.cancelled = "Cancelled"
exports.shipped = "Shipped"
exports.buyersInfo = "Buyer's Information"
exports.shippingAdress  = "Shipping address :"
exports.name = "Name"
exports.phoneNumber  = "Phone number"
exports.orderDetails = "Order details"
exports.orderId = "Order Id"
exports.orderPlacedOn = "Order placed on"
exports.productName = "Product name"
exports.color = "Colour"
exports.quantity = "Quantity"
exports.paymentStatus = "Payment status"
exports.acceptOrder = "Accept Order"
exports.cancelOrder = "Cancel Order"
exports.amount = "Amount"
exports.pending = "Pending"


exports.placed =  "placed"
exports.accepted =  "accepted"
exports.shipped =  "shipped"
exports.declined =  "declined"
exports.on_hold = "on_hold"
exports.deliveredByTxt = "Delivered by :";
exports.allTxt = "All";
exports.dispatchTxt = "Dispatch";
exports.deliveredTxt = "Delivered"
exports.declinedTxt = "Cancelled";
exports.returnedTxt = "Returned";
exports.expectedTxt = "Expected Delivery :";
exports.contactTxt = "Contact :";
exports.pinCodeTxt = "Pin :"
exports.pendingTxt = "pending";
exports.deliveredStatus = 'delivered';
// Customizable Area End