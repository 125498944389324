import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Drawer,
  Chip,
  Avatar,
  Modal,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import { calenderIcon, chatBackground, createGroupIcon, profileIcon, emojiIcon, hamburgerIcon, newChannelIcon, newMessageIcon, pinIcon, searchIcon, bellIcon, backButton, flixooLoader, plusSvgIcon, tickSvg, infoIcon, offerAccepted, offerDeclined, vectorIcn } from "./assets";
const configJSON = require("./config");
import { Account, Buyer, ExtendedUserMessages } from "./SellerChatController.web";
import moment from "moment";
import data from "@emoji-mart/data";
import Picker from '@emoji-mart/react'; 
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Lottie from "lottie-react";
import DoneAllOutlinedIcon from '@material-ui/icons/DoneAllOutlined';
import SendSharpIcon from '@material-ui/icons/SendSharp';
import Pagination from '@material-ui/lab/Pagination';
let config = require('../../../framework/src/config');
import MessageModalWeb from "../../../components/src/MessageModal.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import LoginFailModalWeb from "../../../components/src/LoginFailModal.web";
// Customizable Area End

import SellerChatController, { Props } from "./SellerChatController.web";

export default class SellerChat extends SellerChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  highlightTextSearch = (text: string, query: string) => {
    if (!query || !text) return text || '';
    
    const regex = 
    new RegExp(`(${query})`, 'gi');
    const parts = 
    text.split(regex);
  
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? 
    (<span key={index} className="highlight">{part}
    </span>
      ) : 
      (part)
    );
  };
  renderLeftSideChatBar = () => {
    return (
      <Box className="drawerBoxMain">
        {this.state.isContactList === false ?
          <Box className="chatHeader">
            <Typography className="sideBartitle">{configJSON.chatText}</Typography>
          </Box>
          :
          <Box className="createGroupContainer">
            <Box className="innerGrpBox">
              <Box className="groupIcons">
                <img src={createGroupIcon} alt="" className="grpIcon" />
              </Box>
              <Box>
                <Typography className="groupSectionTxt">
                  {configJSON.createNewGroup}
                </Typography>
              </Box>
            </Box>
            <Box className="innerGrpBox">
              <Box className="groupIcons">
                <img src={newChannelIcon} alt="" className="grpIcon" />
              </Box>
              <Box>
                <Typography className="groupSectionTxt">{configJSON.newChannelTxt}</Typography>
              </Box>
            </Box>
          </Box>
        }
        {this.state.isContactList === false ? this.renderChatPeople() : this.renderBuyerContact()}
        <Box className="addButtonBox">
          <IconButton className="addButton" data-test-id="contactListBtn" onClick={this.handleContactList}>
            <img src={plusSvgIcon} className="plusIconLeftHeader" />
          </IconButton>
        </Box>
      </Box>
    );
  };

  renderChatPeople = () => {
    const isEmpty = this.state.buyerList.length === 0;
    return (
      <Box className={`chatPeopleContainer`}>
        {this.state.buyerList.length > 0 && this.state.buyerList.map((buyerListData: Buyer, sellerChatIdx: number) => {
          const highlightedUserName = this.highlightTextSearch(buyerListData.buyerName, this.state.searchQuery);
          return (<Box
            key={sellerChatIdx}
            className={`personInfoBox ${this.state.selectedBuyer?.buyerId === buyerListData?.buyerId && "currentChatBox"}`}
            onClick={() => this.handleSetBuyerChannel(buyerListData,sellerChatIdx)}
            data-test-id={`chatContainerTestId${sellerChatIdx}`}
          >
            <Box className="personDetails">
              <Box className="avatarBox">
                <img src={(buyerListData?.profileUrl === "" || buyerListData?.profileUrl === null) ? profileIcon : buyerListData?.profileUrl} className="personAvatar" />
              </Box>
              <Box className="rightInfoBox">
                <Box className="rightInfoTopBox">
                  <Box>
                    <Typography className="personNameTxt">
                    {highlightedUserName}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={`dateTxt ${this.state.selectedBuyer?.buyerId === buyerListData?.buyerId && "active"}`}>
                      {(buyerListData?.lastMessageTime !== null && buyerListData.lastMessageTime !== 0) ? moment(buyerListData?.lastMessageTime).format('hh:mm') : ""}
                    </Typography>
                  </Box>
                </Box>
                <Box className="righInfoBottomBox">
                  <Typography className="lastMessageTxt">
                    {this.checkLastMessage(buyerListData)}
                  </Typography>
                  {buyerListData.unreadCount !== 0 && <Box className="chipBox">{buyerListData.unreadCount}</Box>}
                </Box>
              </Box>
            </Box>
          </Box>
        )})}
        {isEmpty && (
              <Typography className="noUserFoundTxt">No user found</Typography>
            )}
      </Box>
    )
  };

  renderBuyerContact = () => {
    const { buyerContactList } = this.state;
    const isEmptyText = buyerContactList.length === 0;
    return (
      <Box>
        <Typography className="contactText">Contact</Typography>
        <Box className={`chatContainerRes`}>
          {this.state.buyerContactList.length > 0 && this.state.buyerContactList.map((buyerChatData: Account, sellerChatIdx: number) => {
            const highlightedUserName = this.highlightTextSearch(buyerChatData.user_name, this.state.searchQuery);
            const highlightedName = this.highlightTextSearch(buyerChatData.name, this.state.searchQuery);
            return(
            <Box
              key={sellerChatIdx}
              className={`personInfoBox`}
              onClick={() => this.handleCreateChannel(buyerChatData?.sendbird_credential?.user_id)}
              data-test-id="contactChannelTestId"
            >
              <Box className="personDetails">
                <Box className="avatarBox">
                  <img src={buyerChatData.profile_photo === null ? profileIcon : buyerChatData.profile_photo} className="personAvatar" />
                </Box>
                <Box className="rightInfoBox">
                  <Box className="rightInfoTopBox">
                    <Box>
                      <Typography className="personNameTxt">
                      <span>{highlightedName}</span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="righInfoBottomBox">
                    <Typography className="lastMessageTxt">
                    <span>{highlightedUserName}</span>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>)}
          )}
        {isEmptyText &&(
        <Typography className="noUserFoundTxt">
          No user found</Typography>
      )}
        </Box>
      </Box>
    )
  };

  leftSideRenderFirstMessage = (chatData: ExtendedUserMessages, chatIndex: number) => {
    const isOnlyMessageCell = this.checkUserMessageType(chatData)
    let isOnlyImageCell = chatData.customType === 'image';
    let isDoc = chatData.customType === 'doc' || chatData.customType === "docx";
    let isPdf = chatData.customType === 'pdf';
    let isOnOrder = chatData.customType === "on_order";
    let isBargain = chatData.customType === "bargain";
    let isAcceptReject = chatData.customType === "bargain_accept" || chatData.customType === "bargain_declined";
    if(isOnlyMessageCell){
      return (
        <Box key={chatIndex} className="receivedChats">
          <Box className="receivedMyCharts">
            <Box className="iconChatStyle"></Box>
            <Typography className={`${chatData.message == '❤️' ? "storyHeart" : "textWidth"}`}>{this.convertCheckHyperLink(chatData.message)}</Typography>
            <Box className="timeAppear">
              <Typography className="times">{moment(chatData.createdAt).format('hh:mm')}</Typography>
            </Box>
          </Box>
        </Box>
      )
    }else if(isOnlyImageCell){
      return(
        <Box key={chatIndex} className="receivedChats">
          <Box className="chatImageMainContainer">
            <img data-test-id={`imageViewTestID${chatIndex}`} src={this.getAuthenticatedAccessUrl(String(chatData?.plainUrl))} onClick={() => this.handleImageModalUrl(String(chatData?.plainUrl))} className="chatImage" />
            <Box 
             className="timeAppear">
              <Typography className="times">
                {moment(chatData.createdAt).format('hh:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    }else if(isDoc){
      return (
        <Box 
          key={chatIndex} 
          className="receivedChats"
         >
          <Box 
           className="docBoxContainer"
           >
            <Box className="innerDocumentBox">
                <Box 
                 className="iconBoxDoc"
                  >
                  <DescriptionIcon 
                   className="docIcon" 
                  />
                </Box>
                <Box 
                  className="documentInfoBox">
                  <span 
                    className="docLink" 
                    data-test-id={`fileTestId${chatIndex}`} 
                    onClick={()=> this.handleClickFileOpen(this.getAuthenticatedAccessUrl(String(chatData?.plainUrl)))} >
                    {this.truncateDocumentName(chatData?.name as string)}
                  </span>
                </Box>
            </Box>
            <Box 
              className="timeAppear"
             >
              <Typography 
              className="times">
              {moment(chatData.createdAt).format('hh:mm')}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    }else if(isPdf){

      return (
        <Box key={chatIndex} className="receivedChats">
          <Box className="docBoxContainer">
            <Box className="innerDocumentBox">
              <Box className="iconBoxDoc">
                <PictureAsPdfIcon className="documentPdfIcon" />
              </Box>
              <Box className="documentInfoBox">
                <span className="docLink" data-test-id={`fileTestId${chatIndex}`} onClick={()=> this.handleClickFileOpen(this.getAuthenticatedAccessUrl(String(chatData?.plainUrl)))} >{this.truncateDocumentName(chatData?.name as string)}</span>
              </Box>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{moment(chatData.createdAt).format('hh:mm')}</Typography>
            </Box>
          </Box>
        </Box>
      )
    } else if (isOnOrder) {
      return <Box>{this.renderOnOrderView(chatData, chatIndex)}</Box>
    } else if (isBargain){
      return <Box>{this.renderBargainMessageView(chatData, chatIndex)}</Box>
    } else if(isAcceptReject){
      return this.renderAcceptRejectLeftSellerView(chatData, chatIndex)
    }
  };

  renderAcceptRejectLeftSellerView = (bargainChatData: ExtendedUserMessages, chatIndex: number) => {
    let bargainData = this.parseBargainData(bargainChatData);
    if (bargainChatData.customType === 'bargain_accept') {
      return (
        <Box className="receivedChats">
          <Box className="receivedMyCharts">
            <Box className="chatOfferStatusBox">
                <img src={offerAccepted} 
                  alt="" 
                  className="offerStatusImg" 
                />
              <Typography className="textWidth">
                {configJSON.acceptedByBuyerTxt}
              </Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">
                {this.formateDateChat(bargainChatData)}
              </Typography>
            </Box>
          </Box>
        </Box>
      )
    } else if (bargainChatData.customType === 'bargain_declined') {
      return (
        <Box>
          <Box className="receivedMyChatsRight marginSpacing">
            <Box className="chatOfferStatusBox">
              <img 
                src={offerDeclined} 
                alt="" 
                className="offerStatusImg" 
              />
              <Typography className="textWidth">{configJSON.declinedByBuyerTxt}</Typography>
            </Box>
            <Box 
              className="timeAppear">
              <Typography 
                className="times">
                {this.formateDateChat(bargainChatData)}
              </Typography>
            </Box>
          </Box>
          {bargainData?.lastPrice != null && this.renderBargainAcceptaLastOfferView(bargainChatData, chatIndex)}
        </Box>
      )
    }
  };

  renderBargainAcceptaLastOfferView = (chatData: ExtendedUserMessages, chatIndex: number) => {
    const bargainLastOfferData = JSON.parse(chatData.message);
    return (
      <Box 
        className="bargainBoxContainer">
        <Box
          className="bargainBoxInnerContainer">
          <Box
            className="onOrderContainer">
            <Box
              className="onoOrderImgBox">
              <img
                src={this.getAuthenticatedAccessUrl(String(bargainLastOfferData?.productImage))}
                alt=""
                className="onOrderImg" 
              />
            </Box>
            <Box
              className="rightDetialBox">
              <Box
                className="rightTopTitleBox">
                <Typography
                  className="prdTitleTxt">
                  {bargainLastOfferData.productTitle}
                  <span className="prdIdTxt">#{bargainLastOfferData.productId}</span>
                </Typography>
              </Box>
              <Typography className="descTxt">
                {bargainLastOfferData.productDesc}
              </Typography>
              <Typography className="onOrderQtyTxt">
                last Price :
                <span className="cntPriceTxt">{"₹"} {bargainLastOfferData.lastPrice}</span>
              </Typography>
            </Box>
          </Box>
          <Box
            className="acceptBargainChatBox">
            <Box
              className="acceptRejectBargainBox">
              <Button className={`acceptLastOfferBargainBtn`}
                onClick={() => this.handleAcceptBargainlastOffer(bargainLastOfferData.bargainId, chatData.messageId, bargainLastOfferData.productId, chatData.message, true)}
                data-test-id={`acceptLastOfferTestId${chatIndex}`}
                disabled={bargainLastOfferData?.bargainStatus === "pending" ? false : true}>
                Accept last offer
              </Button>
              <Button className={`declineOrderBargainBtn`}
                onClick={() => this.handleDeclineBargainOffer(bargainLastOfferData.bargainId, chatData.messageId, bargainLastOfferData.productId, chatData.message, false, 'bargain')}
                data-test-id={`declineLastOfferTestId${chatIndex}`} disabled={bargainLastOfferData?.bargainStatus === "pending" ? false : true}>
                Decline
              </Button>
              <Button className={`makeOfferChatBtn`}
                data-test-id={`makeLastOffertestId${chatIndex}`}
                onClick={() => this.handleOfferModal(bargainLastOfferData.bargainId, chatData.messageId, bargainLastOfferData.productId, chatData.message, false)}
                disabled={bargainLastOfferData?.bargainStatus === "pending" ? false : true}>
                Make an offer
              </Button>
            </Box>
          </Box>
          <Box className="timeAppear">
            <Typography
              className="times">
              {this.formateDateChat(chatData)}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  };

  renderBargainMessageView = (chatData: ExtendedUserMessages, chatIndex: number) => {
    let bargainData = JSON.parse(chatData.message);

    return (
      <Box key={chatIndex} className="bargainBoxContainer">
        <Box  className="bargainBoxInnerContainer">
          <Box className="onOrderContainer">
            <Box className="onoOrderImgBox">
              <img src={this.getAuthenticatedAccessUrl(String(bargainData?.productImage))} alt="" className="onOrderImg" />
            </Box>
            <Box className="rightDetialBox">
              <Box className="rightTopTitleBox">
                <Typography className="prdTitleTxt">
                  {bargainData.productTitle} <span className="prdIdTxt">#{bargainData.productId}</span>
                </Typography>
              </Box>
              <Typography className="descTxt">{bargainData.productDesc}</Typography>
              <Typography className="onOrderQtyTxt">Ask Price : <span className="cntPriceTxt">{"₹"} {bargainData.askPrice}</span></Typography>
            </Box>
          </Box>
          <Box className="acceptBargainChatBox">
          <Box className="acceptRejectBargainBox">
              {chatData?.timeLeft && chatData?.timeLeft > 0 && bargainData?.bargainStatus === 'pending' && (
                <Box position="relative" display="inline-flex">
                  <CircularProgress
                    variant="determinate"
                    value={100}
                    style={{ color: "#979797", position: 'absolute' }}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={(chatData?.timeLeft / 900000) * 100}
                    style={{ color: "yellow" }}
                  />
                  <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
                    <Typography className="timerText" >
                      {chatData?.timeLeft !== 0 && this.formatMillisecondsToMMSS(chatData?.timeLeft)}
                    </Typography>
                  </Box>
                </Box>
              )}
              <Button className={`acceptOrderBargainBtn`} onClick={() => this.handleAcceptBargainOffer(bargainData.bargainId, chatData.messageId, bargainData.productId, chatData.message, true)} data-test-id={`acceptOrderBargainTestId${chatIndex}`} disabled={this.checkIsBargainEnable(bargainData, chatData)} >Accept</Button>
              <Button className={`declineOrderBargainBtn`} onClick={() => this.handleDeclineBargainOffer(bargainData.bargainId,chatData.messageId, bargainData.productId, chatData.message, false, 'bargain')} data-test-id={`declineOrderBargainBtn${chatIndex}`} disabled={this.checkIsBargainEnable(bargainData, chatData)} >Decline</Button>
              <Button className={`makeOfferChatBtn`} data-test-id={`makeOfferChatBtn${chatIndex}`} onClick={() => this.handleOfferModal(bargainData.bargainId, chatData.messageId, bargainData.productId, chatData.message, false)} disabled={this.checkIsBargainEnable(bargainData, chatData)} >Make an offer</Button>
          </Box>
        </Box>
          <Box
            className="timeAppear">
            <Typography
              className="times">
              {this.formateDateChat(chatData)}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  };

  renderCounterOfferModal = () => {
    return (
      <CounterOfferModal
        open={this.state.isCounterOfferModal}
        onClose={this.handleCounterOfferModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        data-test-id="counterModalTestId"
      >
        <Box className="counterMainContainer">
          <Box className="offerBox">
            <Typography className="counterOfferLabel">Custom pricing</Typography>
            <Typography className="reasonSelectDesc"> <img src={infoIcon} className="infomationIcon" />In this section, enter the customised pricing you agreed upon.</Typography>
            <Box className="inputFeild">
              <TextField
                className="counterOfferTextField"
                type="text"
                id="outlined-textarea"
                data-test-id="customPriceInputTestId"
                label="Custom Price"
                variant="outlined"
                placeholder="₹ 0"
                onChange={this.handleCounterOfferChange}
                value={this.state.counterOfferVal}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <Typography className="errorTxt">{this.state.counterOfferError}</Typography>
            </Box>
            <Button className="submitBtn" data-test-id="sendCounterOfferTestId" onClick={this.handleSellerCounterOfferSubmit}>Send</Button>
          </Box>
        </Box>
      </CounterOfferModal>
    )
  };

  renderOnOrderView = (chatData: ExtendedUserMessages, chatIndex: number) => {
    let onOrderInfo = JSON.parse(chatData.message);
    return (
      <Box key={chatIndex} className="receivedChats">
        <Box className="receivedMyCharts">
          <Box className="onOrderContainer">
            <Box className="onoOrderImgBox">
              <img src={this.getAuthenticatedAccessUrl(String(onOrderInfo?.productUrl))} alt="" className="onOrderImg" />
            </Box>
            <Box className="rightDetialBox">
              <Box className="rightTopTitleBox">
                <Typography className="prdTitleTxt">
                  {onOrderInfo.title} <span className="prdIdTxt">#{onOrderInfo.productId}</span>
                </Typography>
              </Box>
              <Typography className="descTxt">{onOrderInfo.description}</Typography>
              <Typography className="onOrderQtyTxt">Quantity :- {onOrderInfo.quantity}</Typography>
              <Typography className="expectedOrderDateTxt">Expected Date :- {moment(onOrderInfo.expectedDate).format('DD/MM/YYYY')}</Typography>
              <Typography className="expectedOrderDateTxt">{configJSON.orderStatusTxt} {this.getStatusText(onOrderInfo.onOrderStatus)}</Typography>
            </Box>
          </Box>
          <Box
            className="timeAppear">
            <Typography
              className="times">
              {moment(chatData.createdAt).format('hh:mm')}
            </Typography>
          </Box>
          <Box className="acceptRejectBox">
          {chatData?.timeLeft && chatData?.timeLeft > 0 && onOrderInfo?.onOrderStatus === 'pending' && (
                 <Box position="relative" display="inline-flex">
                 <CircularProgress variant="determinate" value={100} style={{ color: "#979797", position:'absolute' }} />
                <CircularProgress variant="determinate" value={(chatData?.timeLeft / 900000) * 100} style={{ color: "yellow" }} />
                <Box
                  top={0}
                  left={0}
                  bottom={0}
                  right={0}
                  position="absolute"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography className="timerText" >{chatData?.timeLeft !== 0 && this.formatMillisecondsToMMSS(chatData?.timeLeft)}</Typography>
                </Box>
              </Box>
              )}
            <Button className="acceptOrderBtn" data-test-id={`onOrderAcceptTestId${chatIndex}`} disabled={this.checkIsOnOrderEnable(onOrderInfo?.onOrderStatus, chatData)} onClick={() => this.handleAcceptOnOrder(onOrderInfo.bargainId, chatData.messageId, onOrderInfo.productId, chatData.message, onOrderInfo.expectedDate, true)}>Accept</Button>
            <Button className="declineOrderBtn" data-test-id={`declineModalTestId${chatIndex}`} disabled={this.checkIsOnOrderEnable(onOrderInfo.onOrderStatus, chatData)} onClick={() => this.handleOnOrderDeclineModal(onOrderInfo.bargainId, chatData.messageId, onOrderInfo.productId, chatData.message, false, 'onOrder')}>Decline</Button>
          </Box>
        </Box>
      </Box>
    )
  };

  getStatusText = (status: string) => {
    switch (status) {
      case "Accepted":
        return <span className="acceptedTxt">{configJSON.acceptTxt}</span>;
      case "Declined":
        return <span className="rejectedTxt">{configJSON.rejectTxt}</span>;
      default:
        return <span className="pendingTxt">{configJSON.pendingTxt}</span>;
    }
  };

  renderDeclineOnOrderModal = () => {
    return (
      <CustomDeclineModal
        open={this.state.isDeclineModal}
        onClose={this.toggleDeclineModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="declineModalMainContainer">
          <Typography className="declineModalTitle">Reason for decline</Typography>
          <Typography className="reasonSelectDesc"> <img src={infoIcon} className="infomationIcon"/>{configJSON.reasonSubtitleTxt}</Typography>
          <RadioGroup aria-label="declineOrder" name="onOrderDecline" data-test-id="declineOnOrderTestId" value={this.state.declineReason} onChange={this.handleDeclineReasonChange} >
            <Box className="radioBox">
              <CustomFormControlLabel value="Overly Complex Requests" control={<Radio />} label="Overly Complex Requests" />
            </Box>
            <Box className="radioBox">
              <CustomFormControlLabel value="Unrealistic Deadlines" control={<Radio />} label="Unrealistic Deadlines" />
            </Box>
            <Box className="radioBox">
              <CustomFormControlLabel value="Mismatch with Brand or Style" control={<Radio />} label="Mismatch with Brand or Style" />
            </Box>
            <Box className="radioBox">
              <CustomFormControlLabel value="Cost Ineffectiveness" control={<Radio />} label="Cost Ineffectiveness" />
            </Box>
            <Box className="radioBox">
              <CustomFormControlLabel value="Other" control={<Radio />} label="Other" />
            </Box>
          </RadioGroup>
           {this.state.declineReason === "Other" && <TextField data-test-id="otherReasonInputTestId" fullWidth multiline minRows={3} maxRows={3} placeholder="Write max 100 words" variant="outlined" value={this.state.declineOtherReason} onChange={this.handleDeclineOtherReason} className="declineReasonInput" label="Reason*" />}
           {this.state.declineOtherErr && <Typography className="errorTxt">{configJSON.pleaseEnterTxt}</Typography>}
          <Button className="submitBtn" data-test-id="submitDeclineTestId" onClick={this.state.declineType === 'onOrder' ? this.handleOnOrderStatusUpdate : this.postSellerDeclineOrderApiCall}>Submit</Button>
        </Box>
      </CustomDeclineModal>
    )
  };

  renderMsgSendStatus = (isRead: boolean, isDelivered: boolean) => {
    if (isRead) {
      return <img src={tickSvg} className="tickImgIcon" />;
    } else if (isDelivered) {
      return <DoneAllOutlinedIcon className="tickImgIcon" />;
    }
    return null;
  };

  convertCheckHyperLink = (userMessage: string) => {
    const urlRegex = configJSON.mediaHyperlinkRegex;
    return userMessage.split(urlRegex).map((userMsg, index) => {
      if (urlRegex.test(userMsg)) {
        return (
          <a key={index} className="hyperlinkTxt" href={userMsg} target="_blank" rel="noopener noreferrer">
            {userMsg}
          </a>
        );
      }
      return userMsg;
    });
  };

  rightSIdeRenderFirstMessage = (chatData: ExtendedUserMessages, chatIndex: number) => {
    let chatMsgData = JSON.parse(chatData.data);
    const isOnlyMessageCell = this.checkUserMessageType(chatData)
    let isOnlyImageCell = chatData.customType === 'image';
    let isDoc = chatData.customType === 'doc' || chatData.customType === "docx";
    let isPdf = chatData.customType === 'pdf';
    let isRightBargain = chatData.customType === "bargain";
    let isAcceptRejectRight = chatData.customType === "bargain_accept" || chatData.customType === "bargain_declined";
    let isSuggested = chatData.customType === "suggested_product";
    if(isOnlyMessageCell){
      return (
        <Box key={chatIndex} className="senderMessage">
          <Box className={`${chatData.message == '❤️' ? "receivedHeartRight" : "receivedMyChatsRight"}`}>
            <Box className="iconChatStyle"></Box>
            <Typography className={`${chatData.message == '❤️' ? "storyHeart" : "textWidth"}`}>{this.convertCheckHyperLink(chatData.message)}</Typography>
            <Box className="timeAppear">
              <Typography className="times">{moment(chatData.createdAt).format('hh:mm')}</Typography>
              <Box>
                {this.renderMsgSendStatus(chatMsgData.read, chatMsgData.delivered)}
                </Box>
            </Box>
          </Box>
        </Box>
      )
    }else if(isOnlyImageCell){
      return (
        <Box key={chatIndex} className="senderMessage1">
          <Box className="chatImageMainContainer">
            <img data-test-id={`imageViewTestID${chatIndex}`} src={this.getAuthenticatedAccessUrl(String(chatData?.plainUrl))} onClick={() => this.handleImageModalUrl(String(chatData?.plainUrl))} className="chatImage" />
            <Box className="timeAppear">
              <Typography className="times">{moment(chatData.createdAt).format('hh:mm')}</Typography>
              <Box>
                {this.renderMsgSendStatus(chatMsgData.read, chatMsgData.delivered)}
                </Box>
            </Box>
          </Box>
          
        </Box>
      )
    }else if(isDoc){
      return (
        <Box key={chatIndex} className="senderMessage">
          <Box 
            className="docBoxContainer">
            <Box 
              className="innerDocumentBox">
                <Box 
                  className="iconBoxDoc">
                  <DescriptionIcon className="docIcon" />
                </Box>
                <Box 
                  className="documentInfoBox">
                  <span className="docLink" onClick={()=> this.handleClickFileOpen(this.getAuthenticatedAccessUrl(String(chatData?.plainUrl)))}
                  data-test-id={`fileTestId${chatIndex}`} 
                  >
                    {this.truncateDocumentName(chatData?.name as string)}
                  </span>
                </Box>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">
                {moment(chatData.createdAt).format('hh:mm')}
              </Typography>
              <Box>
                {this.renderMsgSendStatus(chatMsgData.read, chatMsgData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }else if(isPdf){
      return (
        <Box key={chatIndex} className="senderMessage">
          <Box 
            className="docBoxContainer">
            <Box 
              className="innerDocumentBox">
              <Box className="iconBoxDoc">
                <PictureAsPdfIcon className="documentPdfIcon" />
              </Box>
              <Box className="documentInfoBox">
                <span className="docLink" onClick={()=> this.handleClickFileOpen(this.getAuthenticatedAccessUrl(String(chatData?.plainUrl)))} 
                 data-test-id={`fileTestId${chatIndex}`}>
                  {this.truncateDocumentName(chatData?.name as string)}
                </span>
              </Box>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">
                {moment(chatData.createdAt).format('hh:mm')}
              </Typography>
              <Box>
                {this.renderMsgSendStatus(chatMsgData.read, chatMsgData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }else if(isRightBargain){
      return this.renderRightBargainRequest(chatData, chatIndex);
    }else if(isAcceptRejectRight){
      return this.renderAcceptRejectSellerRightView(chatData);
    }else if(isSuggested){
      return this.renderRightSideSuggestedProduct(chatData, chatIndex);
    }
  };

  renderRightSideSuggestedProduct = (chatData: ExtendedUserMessages, chatIndex: number) => {
    let sugestedData = JSON.parse(chatData.message);
    let suggestedReadData = JSON.parse(chatData.data);
    return (
      <Box key={chatIndex} className="senderMessage">
        <Box className="bargainBoxContainer">
          <Box className="bargainBoxInnerContainer suggestionNavigation" data-test-id={`navigateToProdTestId${chatIndex}`} onClick={()=> this.handleNavigateToProductDesc(sugestedData.productId)}>
            <Typography className="onOrderQtyTxt">Suggested Product</Typography>
            <Box className="suggestionContainerMain">
              <Box className="onoOrderImgBox">
                <img src={config.baseURL + sugestedData.productUrl} alt="" className="onOrderImg" />
              </Box>
              <Box className="rightDetialBox newSuggestionDetailBox">
                <Box className="rightTopTitleBox">
                  <Typography className="titleSuggestedTxt">
                    {sugestedData.title}
                  </Typography>
                </Box>
                <Typography className="descriptionTxt">
                    {sugestedData.description} 
                  </Typography>
                <Box className="variantsBox">
                  <Box>
                    <Typography className="variantTextSugested">Colors Available :</Typography>
                  </Box>
                  <Box className="colorMainContainer">
                    {sugestedData?.colors?.map((color: string) => (
                      <Box className="colorBox" style={{background: color}} >
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              className="timeAppear">
              <Typography
                className="times">
                {this.formateDateChat(chatData)}
              </Typography>
              <Box>
                {this.renderMsgSendStatus(suggestedReadData.read, suggestedReadData.delivered)}
            </Box>
            </Box>

          </Box>
        </Box>
      </Box>
    )
  }

  renderAcceptRejectSellerRightView = (bargainChatData: ExtendedUserMessages) => {
    let bargainReadData = JSON.parse(bargainChatData.data);
    if (bargainChatData.customType === 'bargain_accept') {
      return (
        <Box
          className="senderMessage">
          <Box 
             className="receivedMyChatsRight">
            <Box className="chatOfferStatusBox">
              <img src={offerAccepted} alt="acceptedImg" className="offerStatusImg" />
              <Typography className="textWidth">{configJSON.acceptedByYouTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formateDateChat(bargainChatData)}</Typography>
              <Box>
                {this.renderMsgSendStatus(bargainReadData.read, bargainReadData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    } else if (bargainChatData.customType === 'bargain_declined') {
      return (
        <Box className="senderMessage">
          <Box className="receivedMyChatsRight">
            <Box className="chatOfferStatusBox">
              <img src={offerDeclined} alt="" className="offerStatusImg" />
              <Typography className="textWidth">{configJSON.declinedByYouTxt}</Typography>
            </Box>
            <Box className="timeAppear">
              <Typography className="times">{this.formateDateChat(bargainChatData)}</Typography>
              <Box>
                {this.renderMsgSendStatus(bargainReadData.read, bargainReadData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
  };

  renderRightBargainRequest = (chatData: ExtendedUserMessages, chatIndex: number) => {
    let bargainData = JSON.parse(chatData.message);
    let bargainReadData = JSON.parse(chatData.data);
    return (
      <Box key={chatIndex} className="senderMessage">
        <Box className="bargainBoxContainer">
          <Box className="bargainBoxInnerContainer">
            <Box className="onOrderContainer">
              <Box className="onoOrderImgBox">
                <img src={this.getAuthenticatedAccessUrl(String(bargainData?.productImage))} alt="" className="onOrderImg" />
              </Box>
              <Box className="rightDetialBox">
                <Box className="rightTopTitleBox">
                  <Typography className="prdTitleTxt">
                    {bargainData.productTitle} <span className="prdIdTxt">#{bargainData.productId}</span>
                  </Typography>
                </Box>
                <Typography className="descTxt">You made an offer for this product</Typography>
                <Typography className="onOrderQtyTxt">Counter offer : <span className="cntPriceTxt">{"₹"} {bargainData.askPrice}</span></Typography>
              </Box>
            </Box>
            <Box
              className="timeAppear">
              <Typography
                className="times">
                {this.formateDateChat(chatData)}
              </Typography>
              <Box>
                {this.renderMsgSendStatus(bargainReadData.read, bargainReadData.delivered)}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  };

  renderDrawer = () => {
    return (
      <CustomDrawer
        anchor={"left"}
        open={this.state.isChatListOpen}
        onClose={this.handleDrawerToggle}
      >
        <SellerChatWrapper>{this.renderLeftSideChatBar()}</SellerChatWrapper>
      </CustomDrawer>
    );
  };

  renderNoContactsScreen = () => {
    return (
      <Box className="noMessageBox">
        <img src={newMessageIcon} alt="" className="newMessagesIcon" />
        <Typography className="sendReceiveMsg">Send and receive messages anytime</Typography>
      </Box>
    )
  };
   
  renderUserChatScreen = () => {
    const {selectedBuyer} = this.state;
    return (
      <Box>
        <Box className="chatViewHeader headerGap">
          <Box className="chatHeaderBox">
          <Box className="activeChatInfoHeader">
            <Box className="profileImgBox">
              <img
                src={(selectedBuyer?.profileUrl === "profile_url" || selectedBuyer?.profileUrl === "" ) ? profileIcon : selectedBuyer?.profileUrl}
                className="personChatAvatar"
              />
            </Box>
            <Box className="infoHeaderRight">
              <Typography className="personNameTxt">{selectedBuyer?.buyerName}</Typography>
              {this.state.isReceiverOnline ? "Online" : "Offline"}
            </Box>
            </Box>
            <Box>
              <Button className="storeIconBtn" onClick={this.handleProductModalChange} data-test-id="suggestBtnTestId">Suggest Product</Button>
            </Box>
          </Box>
        </Box>
        <Box className="chatMainDateBox">
          <Box className="timeStampBox">
            <Typography className="timeText">{this.state.chatDateLabel}</Typography>
          </Box>
        </Box>
        <ChatContainerWrapper>
          <div className="mainChatContainer" ref={this.chatContainerRef}>
            {this.state.isLoading &&
              <Box className="loaderBox">
                <Lottie animationData={flixooLoader} loop={true}className="chatMessagesloader" />
              </Box>}
            {this.state.messages &&
              this.state.messages.map(
                (chat: ExtendedUserMessages, chatIndex: number) => {
                  const isCurrentUser = this.state.currentUserID === chat?.sender?.userId;
                  return (
                   <span key={chatIndex}>
                      {isCurrentUser 
                        ? this.rightSIdeRenderFirstMessage(
                          chat,
                          chatIndex
                        )
                        : this.leftSideRenderFirstMessage(
                          chat,
                          chatIndex
                        )}
                    </span>
                  )
                }
              )}
          </div>
        </ChatContainerWrapper>
        <Box className="inputMainContainer">
          {this.state.isFileModal ? this.renderFileSelectedModal() : null}
          <Box className="emojiPicker">
            {this.state.isEmoji && (
              <div ref={this.emojiPickerRef}>
                <Picker
                  previewPosition="none"
                  data={data}
                  data-testId="emojiPickerChangeId"
                  onEmojiSelect={this.handleEmojiMartSelect}
                />
              </div>
            )}
          </Box>
          <Box className="inputInnerContainer">
          <input type="file" hidden accept="image/*,.doc,.docx,.pdf" data-test-id="inputFileTestId" className="fileInput" ref={this.fileRef} onChange={this.handleFileChange} />
            <TextField
              fullWidth
              placeholder="Type your message here"
              className="messageInputField"
              data-test-id="inputMessageTestId"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton className="textFieldEndIcons" data-test-id="emojiPickerTestID" onClick={this.handleEmojiMartPicker} >
                      <img src={emojiIcon} className="endIcon" />
                    </IconButton>
                    <IconButton className="textFieldEndIcons" data-test-id="fileOpenTestId" onClick={this.handleFileClick}>
                      <img src={pinIcon} className="endIcon" />
                    </IconButton>
                  </InputAdornment>
                ),
                disableUnderline: true,
              }}
              onChange={this.handleMessageChange}
              value={this.state.newMessage}
              onKeyPress={this.handleEnterPress}
            />

            <Button className="sendMsgButton" data-test-id="sendButtonTestId" onClick={this.handleKeyPress}>
              <SendSharpIcon className="sendIcon" />
            </Button>
          </Box>
        </Box>
      </Box>
    )
  };

  renderFileSelectedModal = () => {
    return (
        <Chip className="imageChipData"
          avatar={<Avatar alt="File" src={this.state.fileCurrUrl} className="chipAvatarFile" />}
          label={this.truncateDocumentName(this.state.imageCurrentName)}
        />
    )
  };

  renderCustomAppLoader = () =>{
    return (
      <Box className="flexBox">
        <Box className="innerBoxLoader">
          <Lottie animationData={flixooLoader} loop={true} className="lottieAppLoader" />
        </Box>
      </Box>
    )
  };

  renderNotAdminVerified = () => {
    return (
      <Box className="notAdminVerifiedBox">
        <img src={newMessageIcon} alt="" />
        <Typography className="modalWaitTitle">Please Wait For Admin Approval</Typography>
        <Typography className="modalWaitSubTitle">You can use chat feature when your profile is completed and admin verified.</Typography>
      </Box>
    )
  };

  renderSellerChatMainContainer = () => {
    return (
      <Box className="sellerChatInnerContainer">
        {this.renderDrawer()}
        {this.state.isSellerAppLoading === true ? this.renderCustomAppLoader() : null}
        <Box className="messageMainContainer">
          <Box className="messagesHeaderContainer">
            <Box className="headerTitleBox">
              {this.state.isContactList ?
                <IconButton className="backIconBox" data-test-id="backButtonTestId" onClick={this.handleClickBack}>
                  <img src={backButton} className="backButtonIcon" />
                </IconButton> :
                <IconButton className="backIconBox" data-test-id="navigateBtnTestId" onClick={this.handleNavigateToDashboard}>
                  <img src={backButton} className="backButtonIcon" />
                </IconButton>
              }
              <Typography className="screenTitle">{this.state.isContactList ? "New Contact" : "Message"}</Typography>
            </Box>
            <Box className="headerInnerContainer">
              <TextField
                fullWidth
                placeholder="Search for users..."
                className="headerSearchInput"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton className="textFieldHeader">
                        <img src={searchIcon} alt="" className="headerSearchIcon" />
                      </IconButton>
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                }}
                onChange={this.state.isContactList? this.handleSellersearchAll: this.handleSearchChats}
                value={this.state.searchQuery}
              />
              <Box className="iconStyle"><img src={calenderIcon} /></Box>
              <Box className="iconStyle"><img src={bellIcon} /></Box>
              <Box className="hamburgerContainer">
                <IconButton className="hamburgerButton" onClick={this.toggleSideBar}>
                  <img src={hamburgerIcon} className="hamburderIcon" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="chatListBtnBox">
          <Button className="userListButton" data-test-id="chatListButton" onClick={this.handleDrawerToggle}>Chat List</Button>
        </Box>
        <Box className="mainContainer">
          <Box className="leftSideBar">
            <SellerChatWrapper>{this.renderLeftSideChatBar()}</SellerChatWrapper>
          </Box>
          <Box className="rightPart">
            {(this.state.isContactList === false && this.state.buyerList.length > 0) ? this.renderUserChatScreen() : this.renderNoContactsScreen()
            }
          </Box>
        </Box>
      </Box>
    )
  };

  
  renderImageViewOpenModal = () => {
    return (
      <CustomImageViewModal
        open={this.state.isImageModalOpen}
        onClose={this.handleImageModalSelect}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box className="imageViewMainBox">
          <img src={this.getAuthenticatedAccessUrl(String(this.state.imageOpenUrl))} alt="" className="imageViewStyle" />
        </Box>
      </CustomImageViewModal>
    )
  };

  renderProductInventorySection = () => {
    return (
      <InventoryWrapper>
        <Box className="productDetailContainer">
          <Box className="headerBoxContainer">
            <Box className="headerBox">
              <Box><IconButton><img src={backButton} className="backButtonIcon" data-test-id="backButtonId" onClick={this.handleProductModalChange} /></IconButton></Box>
              <Typography className="titleText">Chat Screen</Typography>
            </Box>
          </Box>
          <Box>
            {this.renderInventorySection()}
          </Box>
        </Box>
      </InventoryWrapper>
    )
  };

  renderInventorySection = () => {
    return (
      <Box>
        {
          this.state.inventoryList.length > 0 ?
            <InventoryContainer data-test-id="invSectionTestID">
              <Pagination className='pagination' data-test-id="paginationTestID" count={this.state.invCurrentTotalPage} size="large" onChange={this.handlePageChange} />
              <InventoryList>
                {this.state.inventoryList.map((inventory, index) => {
                  return (
                    <li key={inventory.id} data-test-id={`addProductTestId${index}`} onClick={() => this.handleAddProduct(inventory)} style={{ background: `${this.handleIsSelectedProduct(inventory.id) ? "yellow" : 'white'}` }}>
                      <Box className="inventoryImage">
                        <img src={config.baseURL + inventory.attributes.product_images[0]?.url} alt={inventory.attributes.title} />
                      </Box>
                      <Box className="inventoryDetails">
                        <Typography className="inventoryName" data-test-id="inventoryTitleID">{inventory.attributes.title}</Typography>
                        <Typography className="inventoryDesc">{inventory.attributes.description}</Typography>
                      </Box>
                      <Typography className="inventoryPrice">${inventory.attributes.mrp}</Typography>
                    </li>
                  )
                })}
              </InventoryList>
              <Box className="buttonBox">
                <Button onClick={this.sendSuggestionMessage} variant="contained" disableElevation data-test-id={'sendProductTestId'} disabled={this.state.selectedProduct === null}>Suggest Product</Button>
              </Box>
            </InventoryContainer>
            :
            <Box className="noInventoryStyle">
              <Typography>No inventory found.</Typography>
            </Box>
        }
      </Box>
    )
  }


  renderScreenOnCondition = () => {
    if (!this.state.isAdminVerified) {
      return this.renderNotAdminVerified();
    } else if (this.state.isInventoryOpen) {
      return this.renderProductInventorySection();
    } else {
      return this.renderSellerChatMainContainer();
    }
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <SellerChatWrapper>
        <Box className="innerMainContainer">
          <div className="dashboard-outer">
            <div className="dashboard-inner">
              <SellerSidebar
                navigation={""}
                id={""}
                classes={undefined}
                isOpen={this.state.isOpen}
                data-test-id="sellerSidebar"
                activetabName="messages"
                closeModal={this.toggleSideBar}
              />
            </div>
          </div>
          <LoginFailModalWeb data-test-id="sellerModal" displayPopup={!this.state.isSellerVerified} closeErrorPopup={this.handleCloseModal} errorMessage={"Your account is not verifed by admin!"} />
          {this.renderImageViewOpenModal()}
          {this.renderScreenOnCondition()}
          {this.renderDeclineOnOrderModal()}
          {this.renderCounterOfferModal()}
          <MessageModalWeb
            data-test-id="errorMessageTestId"
            displayPopup={this.state.isNoVariant}
            closeErrorPopup={this.errorPostModalClose}
            errorMessage={this.state.errorMsgPost}
          />
        </Box>
      </SellerChatWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const SellerChatWrapper = styled(Box)({
  "& .innerMainContainer": {
    margin: "5em 0em 0em",
    display: "flex",
  },
  "& .notAdminVerifiedBox": {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection:"column",
    gap:"20px",
  },
  "& .modalWaitTitle":{
    fontSize: '20px', 
    fontWeight: 600, 
    color: '#444444' 
  },
  "& .modalWaitSubtitle":{
    fontSize: '16px', 
    color: '#444444CC', 
    textAlign: 'center',
    margin:"0px 10rem",
  },
  "& .sellerChatInnerContainer": {
    paddingTop: "4%",
    width: "100%",
  },
  "& .mainContainer": {
    display: "flex",
    padding: "40px",
    gap: "20px",
    width: "100%",
    maxHeight:"135vh",
    "@media(max-width:600px)": {
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "@media(max-width:1024px)": {
      padding: "10px",
      gap:"10px"
    },
  },
  "& .leftSideBar": {
    background: "#FFFFFF",
    width: "40%",
    boxShadow: "0px 4px 20px 0px #0000001A",
    borderRadius: "20px",
    "@media(max-width: 600px)": {
      display: "none",
    },
    "@media(max-width:1390px)": {
      width: "380px"
    },
    height:"100%"
  },
  "& .drawerBtn": {
    display: "none",
    "@media(max-width:600px)": {
      display: "block",
    },
  },
  "& .drawerBoxMain": {
    "@media(max-width:600px)": {
      width: "380px",
    },
  },
  "& .highlight" :{
    backgroundColor: "yellow",
    fontWeight: "bold"
  },
  "& .rightPart": {
    width: "60%",
    backgroundImage: `url(${chatBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    border: "1px solid #5555554D",
    borderRadius: "20px",
    "@media(max-width:600px)": {
      width: "100%",
      padding: "10px",
      paddingRight: "20px",
      paddingBottom:"10px"
    },
    height:"100%"
  },
  "& .noMessageBox":{
    backgroundImage: `url(${chatBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    padding:"20px",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    flexDirection:"column",
    height:"100vh",
    gap:"10px",
    textAlign:"center"
  },
  "& .newMessagesIcon":{
    width:"166px",
    height:"67px",
  },
  "& .sendReceiveMsg":{
      fontSize:"20px",
      fontWeight:400,
      color:"#000000"
  },
  "& .chatHeader": {
    alignItems: "center",
    padding: "0px 20px",
    backgroundColor: "transparent",
    borderRadius: "20px",
  },
  "& .chatViewHeader": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #CCCCCC",
    alignItems: "center",
    height: "99px",
    padding: "0px 20px",
    backgroundColor: "white",
    borderTopRightRadius: "20px",
    borderTopLeftRadius: "20px",
  },
  "& .sideBartitle": {
    fontWeight: 600,
    fontSize: "20px",
    lineHeight: "30px",
    padding: "20px",
  },
  "& .addIcon": {
    width: "24px",
    height: "24px",
  },
  "& .searchInputField": {
    width: "355px",
    border: "1px solid #DCDCDC",
    borderRadius: "39px",
    padding: "10px 20px",
  },
  "& .searchInputField:placeholder": {
    color: "#00000080",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  "& .searchBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "20px",
  },
  "& .searchIcon": {
    marginRight: "10px",
    color: "#00000080",
    width: "20px",
    height: "20px",
  },
  "& .personInfoBox": {
    display: "flex",
    marginTop: "20px",
    marginLeft: "1px",
    paddingLeft: "22px",
    padding: "10px",
    cursor:"pointer",
  },
  "& .personDetails": {
    height: "48px",
    display: "flex",
    gap: "10px",
    width: "100%"
  },
  "& .rightInfoBox": {
    width: "100%",
  },
  "& .personAvatar": {
    width: "50px",
    height: "50px",
    borderRadius: "24px",
    objectFit: "cover",
  },
  "& .personChatAvatar": {
    width: "60px",
    height: "60px",
    borderRadius: "50px",
    objectFit: "cover",
  },
  "& .rightInfoTopBox": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  "& .dateTxt": {
    color: "#B4B4B4",
    lineHeight: "18px",
    fontWeight: 500,
    fontSize: "12px",
  },
  "& .personNameTxt": {
    color: "#000000",
    lineHeight: "21px",
    fontWeight: 600,
    fontSize: "18px",
    "@media(max-width:1390px)": {
      fontSize: "16px"
    }
  },
  "& .storeIconBtn":{
    textTransform:"none"
  },
  "& .chatHeaderBox":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%"
  },
  "& .headerBox":{
     display:"flex",
     justifyContent:"center",
     alignItems:"center",
     marginLeft:"20px"
  },
  "& .chatPeopleContainer": {
    height: "640px",
    overflowY: "scroll",
    "@media(max-width:600px)": {
      height: "840px",
    },
    scrollbarWidth:"none",
  },
  "& .chatPeopleContainer::-webkit-scrollbar ": {
    display: "none",
  },
  "& .chatContainerRes":{
    height: "500px",
    overflowY: "scroll",
    scrollbarWidth:"none",
    "@media(max-width:600px)": {
      height: "840px",
    },
  },
  "& .currentChatBox": {
    borderLeft: "7px solid #F5EA16",
    paddingLeft: "16px",
    backgroundColor: "#F5EA161A",
  },
  "& .righInfoBottomBox": {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
  },
  "& .lastMessageTxt": {
    fontSize: "12px",
    color: "#000000",
    lineHeight: "18px",
    fontWeight: 400,
  },
  "& .noUserFoundTxt" : {
    color: "#b6b6b6",
    textAlign: "center",
    marginTop: "15px"
  },
  "& .lastMsgAttachment": {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  "& .attachmentIcon":{
    marginRight:"5px",
    color:"grey",
  },
  "& .chipBox": {
    height: "20px",
    width: "20px",
    borderRadius: "50px",
    backgroundColor: "#F5EA16",
    fontSize: "12px",
    textAlign: "center",
  },
  "& .active": {
    color: "#F5EA16",
  },
  "& .activeChatInfoHeader": {
    gap: "15px",
    display: "flex",
    width:"100%"
  },
  "& .activeStatusTxt": {
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "21px",
    color: "#444444CC",
  },
  "& .infoHeaderRight": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  "& .chatMainDateBox":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"5px",
    background:"transparent"
  },
  "& .timeStampBox":{
    backgroundColor: "#F5EA1633",
    borderRadius: "12px",
    padding:"4px 16px",
  },
  "& .timeText": {
    color:"#55555580",
    fontWeight:50000000,
    textAlign:"center",
    fontSize:"12px"
  },
  "& .headerGap": {
    padding: "10px 50px",
  },
  "& .inputMainContainer": {
    bottom: "0px",
    top: "100%",
    width: "100%",
    position: "sticky",
  },
  "& .imageChipData": {
    marginLeft: "35px",
    height: "max-content",
    background:"#F5EA1666",
    padding:"4px",
    "& .MuiAvatar-img":{
      objectFit:"contain"
    },
    "@media(max-width:1150px)":{
      bottom: "115px",
    },
    "@media(max-width:1000px)":{
      bottom: "46px",
    },
  },
  "& .chipAvatarFile":{
    background:"black",
    objectFit:"contain"
  },
  "& .textFieldEndIcons": {
      padding: "6px"
   },
  "& .emojiPicker":{
    position: "fixed",
    bottom: "70px",
    marginLeft: "7%",
    marginBottom:"3%"
  },
  "& .inputInnerContainer": {
    width: "100%",
    padding: "20px",
    display: "flex",
    gap: "10px",
    paddingTop:"6px",
  },
  "& .sendMsgButton":{
    border: "1px solid #55555533",
    outline: "none",
    borderRadius: "100%",
    backgroundColor: "#F5EA16",
    minWidth:"0px",
    width:"65px",
    height:"55px"
  },
  "& .sendIcon":{
    width:"20px",
    height:"20px"
  },
  "& .messageInputField": {
    width: "100%",
    height: "53px",
    borderRadius: "10px",
    border: "1px solid #55555533",
    background: "#FFF",
    padding: "10px 12px",
  },
  "& .micBox": {
    width: "53px",
    height: "53px",
    backgroundColor: "#F5EA16",
    borderRadius: "50px",
    borderRight: "3px solid #55555533",
    borderTop: "1px solid #55555533",
    borderLeft: "1px solid #55555533",
    borderBottom: "1px solid #55555533",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  "& .micIcon":{
    color:"#292D32",
    width:"24px",
    height:"24px"
  },
  "& .headerSearchInput": {
    maxWidth: "482px",
    width: "482px",
    height: "48px",
    fontSize: "14px",
    color: "#44444480",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
    borderRadius: "10px",
    padding: "10px",
    border:"1px solid #FFFFFF",
    "@media(max-width:1000px)":{
      width: "100%",
      maxWidth: "482px",
    },
  },
  "& .iconStyle": {
    height: "48px",
    width: "48px",
    border: "1px solid var(--0, #FFF)",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
    background: "var(--0, #FFF)",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  "& .headerInnerContainer": {
    display: "flex",
    justifyContent: "space-evenly",
    gap: "22px",
  },
  "& .messagesHeaderContainer": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "12px",
    padding: "0em 3em",
    alignItems: "center",
    width: "100%",
    "@media(max-width: 656px)": {
      padding: "0em 1em",
    },
  },
  "& .headerTitleBox":{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap:"10px"
  },
  "& .backButtonIcon":{
    color:"#444444CC",
    width:"24px",
    height:"24px",
  },
  "& .screenTitle": {
    color: "#444444CC",
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .addButton": {
    width: "56px",
    height: "56px",
    backgroundColor: "#F5EA16",
    borderRadius: "50px",
    boxShadow: "0px 4px 6px 0px #47440826",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .flexBox":{
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 2,
  },
  "& .innerBoxLoader":{
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  "& .lottieAppLoader":{
    width:"250px",
    height:"250px"
  },
  "& .plusIconLeftHeader":{
    width:"28px",
    height:"28px",
    color:"black",
  },
  "& .addButtonBox": {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    paddingTop: "12px",
    paddingBottom:"20px"
  },
  "& .hamburgerContainer": {
    height: "48px",
    width: "48px",
    border: "1px solid var(--0, #FFF)",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px 1px rgba(0, 0, 0, 0.10)",
    display:"none",
    "@media(max-width:992px)":{
      display:"block"
    },
  },
  "& .timing": {
    display: "flex",
    justifyContent: "center",
    marginTop: "10px"
  },
  "& .time": {
    backgroundColor: "yellow",
    padding: "5px 10px",
    borderRadius: "7px"
  },
  "& .userListButton":{
    textTransform:"none",
    backgroundColor:"#F5EA16"
  },
  "& .chatListBtnBox":{
    marginTop:"12px",
    display:"none",
    "@media(max-width:600px)":{
      display:"block",
      float:"right"
    }
  },
  "& .createGroupContainer": {
    flexDirection: "column",
    display: "flex",
    padding: "20px 20px",
    gap: "14px",
  },
  "& .groupIcons": {
    width: "48px",
    backgroundColor: "#F5EA16",
    height: "48px",
    display:"flex",
    borderRadius: "50px",
    alignItems:"center",
    justifyContent:"center",
  },
  "& .innerGrpBox": {
    alignItems: "center",
    display: "flex",
    cursor:"pointer",
    gap: "20px",
  },
  "& .groupSectionTxt": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  }, 
  "& .grpIcon":{
    height:"24px",
    width:"24px",
  },
  "& .contactText":{
    fontSize:"16px",
    fontWeight:500,
    color:"#444444CC",
    margin:"0px 24px"
  }
});
const ChatContainerWrapper = styled(Box)({
  "& .timing": {
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
  },
  "& .time": {
      backgroundColor: "yellow",
      padding: "5px 10px",
      borderRadius: "7px"
  },
  "& .sender": {
      border: "1px solid black"
  },
  "& .text": {
      display: "flex"
  },
  "& .receivedChats": {
      margin: "16px 20px",
  },
  "& .offerStatusImg": {
    width: "22px",
    height: "22px",
    marginRight:"8px"
  },
  "& .timerBox": {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    border: "4px solid",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .timerText": {
    color: "rgba(68, 68, 68, 1)",
    fontWeight: 500,
    fontSize: "10px",
    fontFamily: "Rubik"
  },
  "& .chatOfferStatusBox":{
      display:"flex",
      paddingTop:"8px",
      alignItems:"center"
  },
  "& .textWidth": {
      marginRight: "60px"
  },
  "& .storyHeart": {
      fontSize: "30px",
      color: "red"
  },
  "& .hyperlinkTxt": {
      color: "#0057ebdb",
  },
  "& .receivedMyCharts": {
      width: "fit-content",
      maxWidth: "50%",
      borderRadius: "10px",
      padding: "10px",
      boxShadow: "0px 6px 20px 0px #0000000D",
      backgroundColor:"#FFFFFF",
      wordBreak:"break-word",
  },
  "& .receivedMyChatsRight": {
    width: "fit-content",
    maxWidth: "50%",
    borderRadius: "10px",
    padding: "10px",
    boxShadow:"0px 4px 20px 0px #0000001A",
    backgroundColor:"#FFFFFF",
    wordBreak:"break-word",
},
  "& .receivedHeartRight": {
      width: "fit-content",
      maxWidth: "50%",
  },
  "& .marginSpacing":{
    margin:"16px 20px"
  },
  "& .chatImageMainContainer": {
    width: "fit-content",
    maxWidth: "50%",
    backgroundColor: "transparent",
    boxShadow:"0px 4px 20px 0px #0000001A",
    borderRadius: "10px",
    padding: "12px 8px"
  },
  "& .chatImage": {
    width: '100%',
    height: 'auto',
    maxWidth: '190px',
    maxHeight: '200px',
    objectFit: "contain",
    padding:"4px",
    marginBottom:"6px",
    borderRadius:"8px"
  },
  "& .docBoxContainer": {
    width: "fit-content",
    maxWidth: "50%",
    backgroundColor: "#FFFFFF",
    boxShadow:"0px 4px 20px 0px #0000001A",
    borderRadius: "10px",
    padding: "8px 10px",

  },
  "& .innerDocumentBox": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding:"1px",
    wordBreak:"break-word",
  },
  "& .docIcon": {
    width: "30px",
    height: "40px"
  },
  "& .documentPdfIcon": {
    width: "30px",
    height: "40px",
    color: "red"
  },
  "& .docLink": {
    fontSize: "14px",
    color: "#000",
    textDecoration: "none",
    cursor: "pointer",

  },
  "& .docLink:hover": {
    cursor: "pointer"
  },
  "& .timeAppear": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "5px",
  },
  "& .iconChatStyle": {
      display: "flex",
      justifyContent: "flex-end",
      height: "5px"
  },
  "& .times": {
      fontSize: "11px",
      color: "grey",
      height: "14px"
  },
  "& .mainChatContainer": {
      height: "590px",
      overflowY: "auto",
      scrollbarWidth:"none",
      backgroundImage: `url(${chatBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
  },
  "& .senderMessage": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: " 5px 20px 16px 20px",
  },
  "& .senderMessage1": {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      margin: "5px 20px 0px 20px",
  },
  "& .loaderBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
  "& .chatMessagesloader":{
    width:"100px",
    height:"100px"
  },
  "& .tickImgIcon":{
    marginLeft:"4px",
    width:"13px",
    height:"12px",
  },
  "& .onOrderContainer":{
    gap:"10px",
    display:"flex",
    padding:"10px",
    flexWrap:"wrap"
  },
  "& .suggestionContainerMain":{
    gap:"10px",
    display:"flex",
    padding:"10px",
    flexWrap:"wrap",
    paddingTop:"0px"
  },
  "& .onoOrderImgBox":{
    height:"100px",
    minWidth:"100px",
    width:"100px",
  },
  "& .onOrderImg":{
    height:"100%",
    objectFit:"contain",
    width:"100%",
  },
  "& .rightDetialBox":{
    flexDirection:"column",
    gap:"4px",
    display:"flex",
  },
  "& .newSuggestionDetailBox":{
    display:"flex",
    justifyContent:"center",
  },
  "& .rightTopTitleBox":{
    alignItems:"center",
    display:"flex",
    gap:"22px",
  },
  "& .prdTitleTxt": {
    fontWeight: 500,
    color: "#444444",
    fontSize: "14px",
  },
  "& .titleSuggestedTxt": {
    fontWeight: 600,
    color: "#444444",
    fontSize: "14px",
  },
  "& .descTxt": {
    fontWeight: 500,
    color: "#444444",
    fontSize: "14px",
  },
  "& .variantTextSugested":{
    fontWeight: 500,
    fontSize: "12px",
  },
  "& .prdIdTxt": {
    fontWeight: 400,
    fontSize: "12px",
    marginLeft:"8px",
    color: "#55555580",
  },
  "& .descriptionTxt": {
    color: "#444444CC",
    fontSize: "14px",
    fontWeight: 400,
  },
  "& .colorBox":{
    border:"1px solid grey",
    width:"20px",
    height:"20px",
    borderRadius:"100px"
  },
  "& .variantsBox":{
    display:"flex",
    flexWrap:"wrap",
    alignItems:"center",
    marginTop:"4px"
  },
  "& .colorMainContainer":{
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"center",
    alignItems:"center",
    gap:"6px",
    marginLeft:"6px"
  },
  "& .onOrderQtyTxt": {
    fontSize: "12px",
    fontWeight: 500,
  },
  "& .cntPriceTxt":{
    fontWeight: 600
  },
  "& .expectedOrderDateTxt": {
    fontSize: "12px",
    fontWeight: 500,
  },
  "& .acceptRejectBox":{
    display:"flex",
    alignItems:"center",
    gap:"20px"
  },
  "& .bargainBoxInnerContainer": {
    padding: "10px",
    borderRadius: "10px",
    backgroundColor: "#FFFFFF",
    wordBreak: "break-word",
    boxShadow: "0px 6px 20px 0px #0000000D",
  },
  "& .suggestionNavigation":{
    cursor:"pointer",
    wordBreak:"keep-all",
    "&:hover":{
      background: "#eaea5c36",
    },
  },
  "& .bargainBoxContainer": {
    margin: "16px 20px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "50%"
  },
  "& .acceptRejectBargainBox": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    gap:"10px",
    flexWrap:"wrap"
  },
  "& .acceptBargainChatBox": {
    marginTop: "10px",
    width: "100%",
    borderRadius: "10px",
    wordBreak: "break-word",
    marginBottom:"10px"
  },
  "& .acceptOrderBargainBtn": {
    textTransform: "none",
    color: "#FFFFFF",
    width: "100%",
    fontWeight: 500,
    background:"#4BB543",
    fontSize: "12px",
    maxWidth:"114px",
    borderRadius:"50px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
    },
  },
  "& .acceptLastOfferBargainBtn": {
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: 500,
    width: "100%",
    fontSize: "12px",
    background:"#4BB543",
    borderRadius:"50px",
    padding:"11px 8px",
    maxWidth:"125px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
    },
  },
  "& .declineOrderBargainBtn": {
    textTransform: "none",
    color: "#FFFFFF",
    width: "100%",
    fontWeight: 500,
    background:"#E46262",
    fontSize: "12px",
    maxWidth:"114px",
    borderRadius:"50px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      background: "grey",
    },
  },
  "& .acceptOrderBtn":{
    textTransform:"none",
    color:"#00A912",
    fontSize:"12px",
    fontWeight:500,
    "&.MuiButton-root.Mui-disabled":{
      color:"grey",
    },
  },
  "& .declineOrderBtn":{
    textTransform:"none",
    color:"#E46262",
    fontSize:"12px",
    fontWeight:500,
    "&.MuiButton-root.Mui-disabled":{
      color:"grey",
    },
  },
  "& .makeOfferChatBtn":{
    color: "#FFFFFF",
    textTransform: "none",
    fontWeight: 500,
    fontSize: "12px",
    width: "100%",
    background:"#66645d",
    maxWidth:"114px",
    borderRadius:"50px",
    padding:"11px",
    "&.MuiButton-root.Mui-disabled": {
      color: "white",
      background: "grey",
    },
  },
  "& .acceptedTxt":{
    color:"#00A912",
    fontWeight:500
  },
  "& .rejectedTxt":{
    color:"#E46262",
    fontWeight:500
  },
  "& .pendingTxt":{
    fontWeight:500,
    color:"grey"
  },
});
const CustomDrawer = styled(Drawer)({
  width: "300px",
});

const CustomImageViewModal = styled(Modal)({
  "& .imageViewMainBox": {
    position: "absolute",
    top: "50%",
    left: "50%",
    border: "none",
    width: "fit-content",  
    alignItems: "center",
    justifyContent:"center",
    maxWidth: "50vw",  
    padding: "10px",
    transform: "translate(-50%, -50%)",
    "@media(max-width:600px)":{
      width:"50%",
    },
    display:"flex",
    maxHeight:"550px",
    background:"transparent",
  },
  "& .imageViewStyle":{
    width:"100%",
    objectFit:"contain",
    maxHeight:"500px"
  }
});

const CounterOfferModal = styled(Modal)({
  "& .counterMainContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    border: "none",
    width: "100%",
    maxWidth: "482px",
    padding: "24px",
    background: "white",
    transform: "translate(-50%, -50%)",
    "@media(max-width:600px)": {
      width: "50%",
    },
    height: "100%",
    minHeight: "308px",
    maxHeight: "310px",
    borderRadius: "20px"
  },
  "& .counterOfferTextField": {
    width: "100%",
    borderRadius: "10px",
    marginTop: "26px",
    minHeight:"56px",
    "& .Mui-focused":  {
      "& fieldset": {
        border: "1px solid black"
      }
    }
  },
  "& .counterOfferLabel": {
    color: "#444444",
    fontSize: "18px",
    fontWeight:500,
  },
  "& .offerBox": {
    flexDirection: "column",
    display: "flex",
  },
  "& .errorTxt":{
    fontSize:"12px",
    color:"red",
    marginTop:"2px"
  },
  "& .submitBtn": {
    fontWeight: 500,
    color: "#555555",
    textTransform: "capitalize",
    fontSize: "14px",
    borderRadius: "20px",
    background: "#F5EA16",
    minHeight: "47px",
    width: "100%",
    marginBottom: "12px",
    marginTop: "20px",
  },
  '& .inputFeild': {
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      fontFamily: 'Poppins-Regular !important',
      color:"#44444480",
      lineHeight: '18px',
    },
    '& .MuiInputBase-input::placeholder': {
      opacity: 0.5,
      color: '#ffffff',
    },
    '& .MuiInputBase-input::focus': {
      border:"1px solid grey",
    },
    '& .BusinessLable.textWhite': {
      lineHeight: '15px',
      color: "#ffffff",
      fontSize: '16px',
    },
    "& .MuiInputBase-input": {
      borderRadius: "20px",
    }
  },
  "& .reasonSelectDesc": {
    fontWeight: 400,
    fontSize: "16px",
    marginTop: "14px",
    marginBottom: "10px",
    color: "#555555B2",
  },
  "& .infomationIcon": {
    marginRight: "4px"
  }
});

const CustomDeclineModal = styled(Modal)({
  "& .declineModalMainContainer": {
    position: "absolute",
    top: "50%",
    left: "50%",
    border: "none",
    width: "100%",
    maxWidth: "482px",
    minxHeight: "620px",
    padding: "10px 20px",
    transform: "translate(-50%, -50%)",
    background: "white",
    borderRadius: "8px",
  },
  "& .radioBox": {
    border: "1px solid #4444444D",
    borderRadius: "10px",
    padding: "8px",
    marginBottom: "10px",
  },
  "& .declineModalTitle": {
    fontSize: "18px",
    color: "#444444",
    fontWeight: 500,
    marginTop: "12px",
  },
  "& .reasonSelectDesc": {
    fontSize: "18px",
    fontWeight: 400,
    color: "#555555B2",
    marginTop: "14px",
    marginBottom: "10px"
  },
  "& .submitBtn": {
    color: "#555555",
    fontWeight: 500,
    fontSize: "14px",
    textTransform: "capitalize",
    background: "#F5EA16",
    borderRadius: "20px",
    width:"100%",
    minHeight:"47px",
    marginTop:"20px",
    marginBottom:"12px",
  },
  "& .errorTxt":{
    color: "red",
    fontSize: "12px",
    marginTop: "6px"
  },
  "& .declineReasonInput":{
    borderRadius:"10px",
    fontSize:"14px",
    color: "#44444480",
    marginTop:"6px"
  },
  "& .infomationIcon":{
    marginRight:"4px"
  }
});

const CustomFormControlLabel = styled(FormControlLabel)({
  fontWeight: 500,
  fontSize:"14px",
  color :"#444444",
  "& .MuiRadio-root": {
    color: "#F5EA16", 
  },
  "& .Mui-checked": {
    color: "#F5EA16", 
  },
});

const InventoryWrapper = styled(Box)({
  width:"100%",
  "& .productDetailContainer": {
    paddingTop:"2%",
    width:"100%",
  },
  "& .headerBoxContainer":{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%"
  },
  "& .titleText":{
    marginLeft:"20px",
    color: "#444444CC",
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .noInventoryStyle":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
});

const InventoryList = styled("ul")({
  padding:"20px",
  marginTop: "20px",
  marginLeft:"10px",
  paddingBottom:"0px",
  "& .custom-checkbox-pseudo input": {
      position: "absolute",
      cursor: "pointer",
      opacity: 0,
  },
  "& .boxShadowVarient": {
      boxShadow: "none !important",
  },
  "& .boxShadowVarientimg": {
      objectFit: "unset" as "unset",
      width: "56.73px !important",
      height: "34.46px !important",
      margin: "0 !important"
  },
  "& li": {
      display: "flex",
      alignItems: "center",
      padding: "20px",
      gap: "12px",
      borderRadius: "20px",
      boxShadow: "0 0 25px rgba(0,0,0,0.2)",
      marginBottom: "20px",
      "& .selectedImages": {
          width: "unset !important",
          height: "inset !important",
          padding: "0 8px 0 0 !important",
          objectFit: "unset" as "unset",
      },
      "& .inventoryImage": {
          width: "106px",
          height: "106px",
          padding: "8px",
          borderRadius: "8px",
          boxShadow: "0 0 5px rgba(0,0,0,0.4)",
          boxSizing: "border-box",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          "& img": {
              objectFit: "cover",
              width: "81px",
              height: "84px",
          },
      },
      "& .inventoryDetails": {
          flex: 1,
          "& .inventoryName": {
              fontWeight: 500,
              color: "rgba(68, 68, 68, 1)",
              fontSize: "16px",
              lineHeight: "24px",
          },
          "& .inventoryDesc": {
              color: "rgba(68, 68, 68, 0.7)",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "21px",
          }
      },
      "& .inventoryPrice": {
          color: "rgba(68, 68, 68, 1)",
          fontSize: "20px",
          fontWeight: 700,
          lineHeight: "30px",
      },
      "& .highlighted":{
        background:"yellow",
      }
  }
});

const InventoryContainer = styled(Box)({
  marginTop: "28px",
  paddingBottom: "20px",
  width: "100%",
  "& .pagination":{
    marginLeft:"20px"
  },
  "& img": {
      marginLeft: "auto",
      cursor: "pointer",
      display: "block"
  },
  "& .MuiButton-contained": {
      backgroundColor: "rgba(245, 234, 22, 1)",
      width: "342px",
      display: "block",
      height: "47px",
      borderRadius: "40px",
  },
  "& .MuiButton-label": {
      textTransform: "capitalize"
  },
  "& .variantButton":{
      width:"100%"
  },
  "& .buttonBox":{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
  },
});
// Customizable Area End
