import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export const baseURL = require("../../../framework/src/config.js").baseURL;
import { getStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
import { Notification, NotificationData } from "./CreateServiceController.web";
export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start
export interface InvalidResponseType {
  errors: string;
}

export interface ValidResponseType {
  meta: object;
  data: object;
}

export interface AllServicesData {
  data: Array<ServiceData>
}

export interface ServiceData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    description: string;
    no_of_post: number;
    no_of_story: number;
    no_of_live_collaboration: number;
    account_id: number;
    created_at: number;
    updated_at: number;
  }
}

export interface DeleteServiceRes {
  success: boolean;
  message: string;
}
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  allNotifications: Array<Notification>;
  isOpen: boolean;
  isNotificationBoxOpen: boolean;
  selectedTab: number;
  accountListOpen: boolean;
  accountListAnchorEl: HTMLElement | null;
  allServices: Array<ServiceData>;
  token: string;
  isLoading: boolean;
  isDeleted: string;
  isDeleteModalOpen: boolean;
  serviceToDelete: string;
  // Customizable Area End
}

interface SS {
  id: number;
  // Customizable Area Start
  // Customizable Area End
}

export default class ServiceOfferedController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllNotificationId: string = '';
  readAllNotificationId: string = '';
  getServicesApiCallId: string = '';
  deleteServiceApiCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      allNotifications: [],
      isOpen: true,
      isNotificationBoxOpen: false,
      selectedTab: 1,
      accountListOpen: false,
      accountListAnchorEl: null,
      allServices: [],
      token: "",
      isLoading: true,
      isDeleted: "",
      isDeleteModalOpen: false,
      serviceToDelete: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJsonData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (this.isValidResponse(responseJsonData)) {
        this.apiSuccessCallBack(apiRequestCallId, responseJsonData);
      }
    }
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const creatorToken = await getStorageData("creatorLogin");
    creatorToken && this.setState({ token: creatorToken });
    this.getAllNotification();
    this.getAllServices();
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: ValidResponseType) => {
    return responseJson;
  };

  apiSuccessCallBack = (apiRequestCallId: string, responseJson: ValidResponseType & NotificationData & AllServicesData & DeleteServiceRes) => {
    if (apiRequestCallId === this.getAllNotificationId) {
      this.getAllNotificationSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getServicesApiCallId) {
      this.getAllServicesSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteServiceApiCallId) {
      this.deleteServicesSuccessCallBack(responseJson);
    }
  };

  apiCall = async (valueData: {
    method?: string;
    contentType?: string;
    body?: {};
    type?: string;
    endPoint?: string;
  }) => {
    const token = this.state.token;
    const { contentType, method, endPoint, body, type } = valueData;
    const header = {
      "Content-Type": contentType,
      token,
    };
    let apiBody = body;
    if (type === "") {
      apiBody = JSON.stringify(body);
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        apiBody
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleNotificationBoxToggle = () => {
    this.setState((prevState) => ({ isNotificationBoxOpen: !prevState.isNotificationBoxOpen }));
    this.markAllNotificationsAsRead();
    this.getAllNotification();
  };

  toggleDrawer = () => {
    this.setState({ isOpen: !this.state.isOpen })
  };

  markAllNotificationsAsRead = async () => {
    this.readAllNotificationId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.readAllNotifications,
        type: ""
      }
    );
  };

  getAllNotification = async () => {
    this.getAllNotificationId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.getAllNotifications,
        type: ""
      }
    );
  };

  getAllServices = async () => {
    this.getServicesApiCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.validationApiMethodType,
        endPoint: configJSON.postCreateServiceEndPoint,
        type: ""
      }
    );
  };

  deleteServiceApi = async (id: string) => {
    this.deleteServiceApiCallId = await this.apiCall(
      {
        contentType: configJSON.validationApiContentType,
        method: configJSON.delete,
        endPoint: `${configJSON.getAllServices}${id}`,
        type: ""
      }
    );
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      return;
    }
    this.setState({
      selectedTab: newValue,
    });
  };

  getAllNotificationSuccessCallBack = (response: NotificationData) => {
    this.setState({
      allNotifications: response.data,
      isLoading: false
    });
  };

  getAllServicesSuccessCallBack = (response: AllServicesData) => {
    this.setState({
      allServices: response.data
    });
  };

  deleteServicesSuccessCallBack = (response: DeleteServiceRes) => {
    this.setState({
      isDeleted: response.message, isLoading: false
    }, () => {
      this.getAllServices();
    });
  };

  CloseAccountList = () => {
    this.setState({
      accountListAnchorEl: null,
      accountListOpen: !this.state.accountListOpen
    });
  };

  OpenAccountList = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({
      accountListAnchorEl: event.currentTarget,
      accountListOpen: !this.state.accountListOpen
    })
  };

  handleDeleteService = (serviceId: string) => {
    this.setState({ isDeleteModalOpen: true, serviceToDelete: serviceId });
  };

  handleDeleteConfirmed = () => {
    const serviceId = this.state.serviceToDelete;
    if (serviceId) {
      this.setState({ isDeleteModalOpen: false, serviceToDelete: "", isLoading: true });
      this.deleteServiceApi(serviceId);
    }
  };

  closeDeleteModal = () => {
    this.setState({ isDeleteModalOpen: false })
  };

  handleEdit = (serviceId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), `EditService`);
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {id: serviceId});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };

  navigateCreateService = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "CreateService");
    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {serviceLength: this.state.allServices.length});
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  };
  // Customizable Area End
}