import React from "react";
// Customizable Area Start
import {
    Button,
    IconButton,
    Tabs,
    Tab,
    Badge,
    Box,
    Avatar,
    styled,
    Typography,
    TextField,
    Modal
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import '../assets/css/styleSeller.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    star,
    calendarIc,
    notificationIcon,
    hamburgerIc,
    fileSucessIc,
    AddYellowIc,
    EditYellowIc,
    bannerImageBlankSeller,
    rightTick,
    dummyProfile,
    group,
    youtubeIcon,
    instagramIcon,
    FlixooIcon,
    group_link
} from "./assets";
import SellerSidebar from "../../landingpage/src/SellerSidebar.web";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover'
import StayTuned from "../../../components/src/StayTuned.web";
import SellerNotificationModal from "../../../components/src/SellerNotificationModal.web";
import CreatorProfileController, { PostDataPayloadType, Props, baseURL } from "./CustomisableSellerProfilesControllerWeb";
import StoryListing from "../../../components/src/CustomStoryListing.web";
import PostCardRow from "../../../components/src/PostCartRow.web";
import { conditionalRenderer } from "../../../components/src/utils";
// Customizable Area End

export default class CreatorProfile extends CreatorProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    abstractSellerData = () => {
        let businessName = ""
        let username = ""
        let profilephoto = ""
        let backgroundphoto = ""
        let role = ""
        let bio = ""
        if (this.state.sellerProfileData) {
            businessName = truthyValue(this.state.sellerProfileData.attributes.business_name)
            username = truthyValue(this.state.sellerProfileData.attributes.user_name)
            profilephoto = truthyValue(this.state.sellerProfileData.attributes.profile_photo)
            backgroundphoto = truthyValue(this.state.sellerProfileData.attributes.background_photo)
            role = truthyValue(this.state.sellerProfileData.attributes.role.name)
            bio = truthyValue(this.state.sellerProfileData.attributes.my_bio)
        }
        return { businessName, username, profilephoto, backgroundphoto, role, bio }
    };

    renderStoryModal = () => {
        return (
            <StoryListing
                data-test-id="storyModalTestID"
                allStoryHeader={this.state.allStoryHeader}
                storyMuted={this.state.storyMuted}
                toggleMute={this.toggleMute}
                calculateHours={this.calculateHours}
                likeStory={this.likeStory}
                setSwiperRef={this.setSwiperRef}
                currentStoryIndex={this.state.currentStoryIndex}
                userStory={this.state.userStory}
                showNextButton={this.showNextButton}
                showPrevButton={this.showPrevButton}
                changeStory={this.changeStory}
                instaModal={this.state.instaModal}
                instaModalIndex={this.state.instaModalIndex}
                handleClose={this.handleClose}
                handleNext={this.handleNext}
                handlePrev={this.handlePrev}
                userID={this.state.BuyerDataId}
                storyId={() => { }}
                commentMessage={() => { }}
                isMessageSent={""}
            />
        );
    };

    parseAndHighlightMentions = (comment: string) => {
        const mentionRegex = /(@\w+)/g;
        const parts = (comment || '').split(mentionRegex);

        return parts.map((part, index) => {
            if (part.startsWith('@')) {
                const username = part.slice(1);
                return (
                    <span key={index} style={{ color: 'rgb(0, 55, 107)' }} className="mention" data-username={username}>
                        {part}
                    </span>
                );
            }
            // Return as plain text
            return <span key={index}>{part}</span>;
        });
    };

    renderSwitchUserModal = () => {
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open={this.state.shitchProfile}
                aria-labelledby="server-modal-title"
                aria-describedby="server-modal-description"
                className="modalContainer"
                onClose={this.handleViewAsBuyer}
                data-test-id="modalTestId"
            >
                <div className="paper">
                    <h3>Switch Profile</h3>
                    <Box className="horizontalLine" />
                    {
                        <Box className="outerWrapperContainer">
                            <Box className="outerWrapperContainer">
                                <img src={this.state.sellerProfileData?.attributes.profile_photo} className="imageClass" alt="profile" />
                                <Box>
                                    <Box className="businessNameHeading">{this.state.sellerProfileData?.attributes.business_name}</Box>
                                    <Box className="businessTypeHeading">Seller's account</Box>
                                </Box>
                            </Box>
                            <Box><img src={rightTick} alt="tick" className="rightTick" /></Box>
                        </Box>
                    }
                    {
                        !this.state.buyerData.account.user_name &&
                        <Box data-test-id="addAccountTestId" className="outerWrapperContainer1" onClick={this.handleAddAccount}>
                            <Box className="outerWrapperContainer1">
                                <img src={dummyProfile} className="imageClass" alt="profile" />
                                <Box>
                                    <Box className="businessNameHeading">Add account</Box>
                                    <Box className="businessTypeHeading">Buyer's's account</Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                    {
                        this.state.buyerData.account.user_name &&
                        <Box data-test-id="switchAccountTestId" className="outerWrapperContainer1" onClick={this.switchToBuyer}>
                            <Box className="outerWrapperContainer1">
                                <img src={dummyProfile} className="imageClass" alt="profile" />
                                <Box>
                                    <Box className="businessNameHeading">{this.state?.buyerData?.account?.user_name}</Box>
                                    <Box className="businessTypeHeading">Buyer's's account</Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                </div>
            </Modal>
        )
    };

    renderProfileSection = () => {
        const { businessName, username, profilephoto, role } = this.abstractSellerData();
        const allRead = this.state.allNotifications?.every(notification => notification.attributes.is_read);
        return (
            <div className="title-wrap">
                <div className="left-wrap">
                    <span className="title">Profile</span>
                </div>
                <div className="right-wrap">
                    <div className="btn-wrap">
                        <div className="account-list" onClick={this.OpenAccountList}>
                            <div className="account-title">{username}</div>
                            <div className="account-list-icon">{this.state.accountListOpen ? <ExpandLess /> : <ExpandMore />}</div>
                        </div>
                        <Popover
                            className="account-list-popover"
                            open={this.state.accountListOpen}
                            onClose={() => this.CloseAccountList()}
                            anchorEl={this.state.accountListAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div className="account-list-details">
                                <div className="person-detail-wrap">
                                    <div className="img-wrap">
                                        <Avatar src={profilephoto} alt="Profile">
                                        </Avatar>
                                    </div>
                                    <div className="detail">
                                        <span className="title">{businessName}</span>
                                        <span className="name">{(role == 'seller' ? "Seller’s account" : 'Buyer’s account')}</span>
                                    </div>
                                    <div className="account-select">
                                        <img src={fileSucessIc} alt="file success" />
                                    </div>
                                </div>
                            </div>
                        </Popover>
                        <IconButton className="icon-btn">
                            <img src={calendarIc} alt="calendar" />
                        </IconButton>
                        <IconButton className="icon-btn" onClick={this.handleNotificationBoxToggle} data-test-id="notificationIcon">
                            <Badge color="secondary" variant={!allRead ? "dot" : "standard"}>
                                <img src={notificationIcon} alt="notification" />
                            </Badge>
                        </IconButton>

                        <IconButton
                            className="icon-btn hamburger-btn"
                            data-test-id="hamburger-btn"
                            onClick={() => this.toggleDrawer()}
                        >
                            <img src={hamburgerIc} alt="hamburger" />

                        </IconButton>
                    </div>
                    <>
                        {this.state.isNotificationBoxOpen && (
                            <SellerNotificationModal closeNotificationBox={this.handleNotificationBoxToggle} allNotifications={this.state.allNotifications} selectedTab={this.state.selectedTab} handleTabChange={this.handleTabChange} />
                        )}
                    </>
                </div>
            </div>
        )
    };

    renderCommentBoxSection = () => {
        return (
            this.state.showCommentBox && (
                <>
                    <div
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 999,
                        }}
                    />
                    <Box
                        className="suggestionCommentBoxPopup"
                        style={{
                            position: "fixed",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: "auto",
                            overflowY: "auto",
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "10px",
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            zIndex: 1000
                        }}
                    >
                        <Box className="commentBoxHeader" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Typography variant="h6">Comments</Typography>
                            <IconButton onClick={this.handleCommentBoxClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>

                        <Box className="suggestionMainCommentContainer" data-testid="suggestion-main-comment-container" style={{ maxHeight: "400px", overflowY: "auto", scrollbarWidth: "none" }}>
                            {this.state.listCommentData.data.map((item, itemIndex) => (
                                <Box id="test4" className="suggestedUserBox" key={itemIndex} style={{ margin: '10px 0px' }}>
                                    <Box className="suggestionLeftBoxForComment" style={{ display: "flex", gap: "12px", alignItems: "start", margin: '10px 0px' }}>
                                        <Box data-test-id="test5" className="avatarSuggestionBox">
                                            <Avatar src={item?.attributes?.details?.profile_photo} className="suggestionAvatar" />
                                        </Box>
                                        <Box id="test6" className="suggestionInfoBox">
                                            <Typography className="userNameTxt foruser">{item?.attributes?.details?.full_name}</Typography>
                                            <Typography data-test-id="test7" className="userFullNameTxt">
                                                {this.parseAndHighlightMentions(String(item?.attributes?.details?.comment || ''))}
                                            </Typography>
                                            <Box className="suggestionLinkComment changesclass" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '15px' }}>
                                                <Typography id="test8" className="userNameTxt">{item?.attributes?.details?.created_at}</Typography>
                                                <Typography
                                                    onClick={() => this.likeCommentApiCall(item.id)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: conditionalRenderer((item.attributes.details.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                    }}
                                                    data-test-id="test9"
                                                    className="userFullNameTxt"
                                                >
                                                    {item?.attributes?.details?.like_count} Likes
                                                </Typography>
                                                <Typography
                                                    className="userFullNameTxt testcases"
                                                    style={{ cursor: 'pointer', color: item.id === this.state.setReplyId ? "rgb(245, 234, 22)" : "" }}
                                                    data-test-id="oneTapReply"
                                                    onClick={() => this.clickOnReplyForGetId(item.id)}
                                                >
                                                    Reply
                                                </Typography>
                                            </Box>

                                            {item?.attributes?.details?.replies_count > 0 && (
                                                <ReplyContainer>
                                                    <div id="test10" className="blankLine"></div>
                                                    <Typography
                                                        data-test-id="clickReply"
                                                        className="text"
                                                        variant="body2"
                                                        onClick={() => this.viewDetilsExpandable(parseInt(item?.id))}
                                                    >
                                                        {!this.state.clickReply.includes(parseInt(item.id)) ? "View" : "Hide"} {item?.attributes?.details?.replies_count} replies
                                                    </Typography>
                                                </ReplyContainer>
                                            )}

                                            {this.state.clickReply.includes(parseInt(item.id)) &&
                                                item.attributes.details.sub_post_comments.map((value, itemIndex) => (
                                                    <Box className="suggestionLeftBoxForComment" key={itemIndex} style={{ display: "flex", gap: "12px", alignItems: "start", margin: '10px 0px' }}>
                                                        <Box className="avatarSuggestionBox">
                                                            <Avatar src={value.profile_photo} className="suggestionAvatar" />
                                                        </Box>
                                                        <Box className="suggestionInfoBox">
                                                            <Typography data-test-id="test21" className="userNameTxt">{value.full_name}</Typography>
                                                            <Typography id="test22" className="userFullNameTxt">
                                                                {this.parseAndHighlightMentions(String(value.comment || ''))}
                                                            </Typography>

                                                            <Box className="suggestionLinkComment adddedclass" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '15px' }}>
                                                                <Typography data-test-id="test23" className="userNameTxt" style={{ marginTop: '4px' }}>
                                                                    {value.created_at}
                                                                </Typography>
                                                                <Typography
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: conditionalRenderer((value.other_like), 'rgb(0, 55, 107)', '#44444480')
                                                                    }}
                                                                    data-test-id="sallerReplyId"
                                                                    onClick={() => this.likeReplyCommentApiCall(value?.sub_comment_id)}
                                                                    id="test24"
                                                                    className="userFullNameTxt"
                                                                >
                                                                    {value.like_count} Likes
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </Box>

                        <CommentContainer>
                            <Avatar
                                alt="User Avatar"
                                className="avatar"
                                src={this.state.profileImage}
                            />
                            <div style={{ position: "relative" }}>
                                <TextField
                                    onChange={this.handleInputChange}
                                    onKeyDown={this.handleKeyDown}
                                    className="input"
                                    fullWidth
                                    data-test-id="changeValueTestId"
                                    value={this.state.addComment}
                                    variant="outlined"
                                    placeholder="Add a Comment"
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <Button
                                onClick={this.state.setReplyId === "" ? this.postCommentApiCall : this.postCommentOnCommentApiCall}
                                color="default"
                                className="postButton"
                                variant="outlined"
                                disabled={this.state.addComment.length === 0}
                            >
                                POST
                            </Button>
                        </CommentContainer>
                    </Box>
                </>
            )
        )
    };

    renderFirstSection = () => {
        return (
            this.state.tabValue === 1 && (
                <div className="seller-tabs-content">
                    <div className="seller-inner-tab-content">
                        <div className="seller-inner-tab-wrap">
                            <div className="post-cards-wrap">
                                {this.state.sellerLiveStreamData.map((item: any) => (
                                    <div className="post-card" key={item.id}>
                                        <div className="inner-wrap">
                                            <div className="img-wrap" onClick={this.openLiveModal}>
                                                <div className="inner-img-wrap">
                                                    <img src={`${baseURL}${item.attributes?.catalogues[0]?.images[0]?.url}`} alt="Card img" />
                                                </div>
                                                <div className="content-wrap-product">
                                                    <span className="product-title">{item.attributes?.catalogues[0]?.catalogue_title}</span>
                                                    <span className="product-content">{item.attributes.catalogues[0]?.catalogue_description}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    };

    renderSecondSection = () => {
        return (
            this.state.tabValue === 2 && (
                <div className="seller-tabs-content">
                    <div className="seller-inner-tab-content">
                        <div className="seller-inner-tab-wrap">
                            <div className="post-cards-wrap">
                                {this.state.sellerTopProduct.map((item: any) => (
                                    <div className="post-card" >
                                        <div className="inner-wrap">
                                            <div className="img-wrap" >
                                                <div className="inner-img-wrap">
                                                    <img src={`${baseURL}${item.attributes.product_images[0].url}`} alt="Card img" onClick={() => this.topProductClick(item.id)} />
                                                </div>
                                                <div className="content-wrap-product">
                                                    <span className="product-title" onClick={() => this.topProductClick(item.id)}>{item.attributes.title}</span>
                                                    <span className="product-content">{item.attributes.description}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )
        )
    };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { businessName, username, profilephoto, backgroundphoto, bio } = this.abstractSellerData();
        // Customizable Area End
        return (
            // Customizable Area Start
            <div className="main-div">
                <div className="dashboard-outer" data-test-id="sellerProfileMainContainer">
                    <div className="dashboard-inner">
                        <SellerSidebar
                            navigation={""}
                            id={""}
                            classes={"profile"}
                            isOpen={this.state.isOpen}
                            data-test-id="sellerSidebar"
                            closeModal={() => this.toggleDrawer()}
                        />
                        <div className="right-outer">
                            <div className="right-inner">
                                {this.renderProfileSection()}
                                <section className="banner-section-seller">
                                    {backgroundphoto ?
                                        <div className="banner-img-wrap-seller">
                                            <img src={backgroundphoto} alt="banner Img" />
                                        </div>
                                        :
                                        <div className="banner-img-wrap-seller no-bg">
                                            <img className="no-bg" src={bannerImageBlankSeller} alt="banner Img" />
                                        </div>
                                    }
                                    <div className="profile-detail-seller">
                                        <div className="profile-detail-inner-seller">
                                            <div className="profile-photo-wrap-seller">
                                                <Avatar src={profilephoto} className="profile-photo-seller">
                                                </Avatar>
                                                <IconButton className="edit-icon">
                                                    <img src={profilephoto ? EditYellowIc : AddYellowIc} alt="Edit" />
                                                    <input
                                                        accept="image/*"
                                                        id="upload-avatar-pic"
                                                        type="file"
                                                        data-test-id="uploadFile"
                                                        className="upload"
                                                        onChange={this.handleSellerProfileImage}
                                                    />
                                                </IconButton>
                                            </div>
                                            <div className="profile-description-wrap">
                                                <div className="name-wrap">
                                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                                                        <span className="name">{businessName}</span>
                                                        <span><img src={group} alt="img" /></span>
                                                        <span style={{ marginLeft: "150px", padding: "2px 10px", borderRadius: "50px", background: "yellow" }}>@{username}</span>
                                                    </div>
                                                    <span className="review">
                                                        4.5
                                                        <i>
                                                            <img src={star} alt="Review" />
                                                        </i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <section className="following-section-seller new-insta-section">
                                    <div className="following-followers-wrap">
                                        <div className="title-number-wrap"
                                            data-test-id="followers"
                                            onClick={() => this.handleOpenModalFollowers()}>
                                            <img src={instagramIcon} alt="" />
                                            <span className="number">{this.state.sellerFollowersData.length}</span>
                                        </div>
                                        <div className="title-number-wrap"
                                            data-test-id="following"
                                            onClick={() => this.openStayTunedBox()}>
                                            <img src={youtubeIcon} alt="" />
                                            <span className="number">0</span>
                                        </div>
                                        <div className="title-number-wrap">
                                            <img src={FlixooIcon} alt="" />
                                            <span className="number">0</span>
                                        </div>
                                    </div>
                                    <div className="business-service-wrap">
                                    </div>
                                    <div className="business-content-wrap">
                                        <p>{bio || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi facilis quidem at quasi cumque quo voluptates a, libero eius! Beatae."}</p>
                                    </div>
                                    <a href="#" className="decoration-none">
                                        <div className="profile-link">
                                            <img src={group_link} alt="" />
                                            instgram.com/johndoe
                                        </div>
                                    </a>
                                    <div className="btn-wrap bio-btns">
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                            onClick={this.handleNavigateServices}
                                        >
                                            Service offered
                                        </Button>
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                        >
                                            Share Profile
                                        </Button>
                                        <Button
                                            className="custom-button primary-yellow seller-profile-yellow"
                                        >
                                            Edit Profile
                                        </Button>
                                        {this.renderSwitchUserModal()}
                                    </div>
                                    <div className="highlights-heading">Visit my mall</div>
                                    <div className="stories-highlights-wrap m-0">
                                        <div className="inner-wrap">
                                            {this.state.userStory.map((item, index) => {
                                                return (
                                                    <>
                                                        {
                                                            item?.attributes?.creator_details?.id == this.state.BuyerDataId &&
                                                            <div className="story" data-test-id={`openStoryTestID${index}`} onClick={() => { this.handleClickOpen(index) }}>
                                                                {item.attributes.media.type === "image" ?
                                                                    <Avatar src={item.attributes.media.url} data-test-id={`imgUserTestID${index}`} className="profile-photo" />
                                                                    : <video src={item.attributes.media.url}
                                                                        autoPlay={false}
                                                                        width={"100%"}
                                                                        height={"100%"}
                                                                        muted={this.state.storyMuted}
                                                                        playsInline />
                                                                }
                                                            </div>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </div>
                                        {this.state.allStoryHeader.length ? this.renderStoryModal() : <></>}
                                    </div>
                                </section>
                                <section className="tabs-section">
                                    <div className="tabs-wrap">
                                        <Tabs
                                            value={this.state.tabValue}
                                            onChange={this.handleChange}
                                            className="custom-tabs-list"
                                            data-test-id="sellerProfileTab"
                                        >
                                            <Tab label="Posts" disableRipple />
                                            <Tab label="Reels" disableRipple />
                                            <Tab label="Content" disableRipple />
                                            <Tab label="Ambassador Shop" disableRipple />
                                        </Tabs>
                                        {this.state.tabValue === 0 && (
                                            <div className="seller-tabs-content">
                                                <div className="seller-inner-tab-content">
                                                    <div className="seller-inner-tab-wrap">
                                                        <div className="post-cards-wrapp">
                                                            <Box className="postInnerContainer">
                                                                {this.state.sellerPostsData.map(
                                                                    (postData: PostDataPayloadType, postIndex: number) => {
                                                                        return (
                                                                            <PostCardRow
                                                                                item={postData}
                                                                                postKeyId={postIndex}
                                                                                postId={postData.id}
                                                                                handleToggleComment={this.handleToggleComment}
                                                                                handleToggleLikePost={this.handlePostLikeUnlike}
                                                                                data-test-id={`postCardComponentTestId${postIndex}`}
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {this.renderCommentBoxSection()}
                                        {this.renderFirstSection()}
                                        {this.renderSecondSection()}
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <StayTuned openTunedBox={this.state.openTunedBox} data-test-id="closeStayTuned" closeTunedBox={this.closeTunedBox} />
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }
}
const ReplyContainer = styled(Box)({
    alignItems: 'center',
    marginTop: '5px',
    display: 'flex',
    '& .blankLine': {
        marginRight: '10px',
        height: '3px',
        background: '#B5B3A76E',
        width: '50px',
    },
    '& .text': {
        cursor: 'pointer',
        color: '#1E1A00',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
})
const CommentContainer = styled(Box)({
    marginRight: '10px',
    marginTop: '20px',
    border: '1px solid black',
    borderRadius: '50px',
    maxWidth: '500px',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    "& .avatar": {
        marginLeft: '10px',
    },
    "& .input": {
        flex: 1,
        '& .MuiOutlinedInput-root': {
            borderColor: 'transparent',
            borderRadius: '30px',
            '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
    },
    "& .postButton": {
        borderRadius: '30px',
        border: 'none',
        fontWeight: 'bold',
        padding: '6px 16px',
        marginLeft: '10px',
    },
})
// Customizable Area End